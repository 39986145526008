import * as types from "@app/types";
import { EventCaseT, EventCase } from "@dariosoft/framework";
import { routeTypes } from "dariosoft/framework/"

export interface IEventService {
    get userSignedIn(): EventCaseT<types.TLoginResult>;
    get userSignedOut(): EventCase;
    get appStarted(): EventCase;
    get appStoped(): EventCase;
}

export class EventService implements IEventService {
    private constructor() {
        // Private constructor to prevent direct instantiation
    }

    private readonly target = new EventServiceTarget();

    private static _service: EventService;

    public static get instance(): IEventService {
        if (!EventService._service) {
            EventService._service = new EventService();
        }
        return EventService._service;
    }

    public readonly appStarted: EventCase = new EventCase(this.target, 'on-app-started');

    public readonly appStoped: EventCase = new EventCase(this.target, 'on-app-stoped');

    public readonly userSignedOut: EventCase = new EventCase(this.target, 'on-user-signed-out');

    public readonly userSignedIn: EventCaseT<types.TLoginResult> = new EventCaseT<types.TLoginResult>(this.target, 'on-user-signed-in');

    public readonly onRouteChanged: EventCaseT<routeTypes.TRouteNode> = new EventCaseT<routeTypes.TRouteNode>(this.target, 'on-route-changed');
}

class EventServiceTarget extends EventTarget{};