export { };

declare global {
    interface Response {
        contentType(): string;
        readAsJson<T = any>(): Promise<T | null>;
    }
}

Response.prototype.contentType = function () {
    return this.headers.get('content-type') || '';
}

Response.prototype.readAsJson = function <T = any>() {

    return new Promise<T | null>(((resolve: (value: T | null) => void) => {
        this.text()
            .then(json => {
                try {
                    resolve(JSON.parse(json));
                }
                catch {
                    resolve(null);
                }
            })
            .catch(error => {
                console.error(`Unable to read the response body.`);
                resolve(null);
            });

    }).bind(this));

}