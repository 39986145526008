import { useMatches } from 'react-router-dom';
import './style.scss';

export function Breadcrumbs() {
    const matches = useMatches();

    return <div className="breadcrumbs">
        <i className="bi bi-house-door"></i>&nbsp;
        <ol>
            {matches
                .distinct(m => m.pathname.trimEndX('/'))
                .map((m, i, items) => {
                    let info: { [key: string]: any } = m.handle || {};
                    return i == (items.length - 1)
                        ? <li key={`breadcrumbs-item-${i + 1}`}><a>{info.title}</a> </li>
                        : <li key={`breadcrumbs-item-${i + 1}`}><a href={`#${m.pathname}`}>{info.title}</a></li>
                })}
        </ol>
    </div>
}