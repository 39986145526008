import './style.scss'
import { AuthService, MainLayoutService } from "@app/services";
import { Breadcrumbs } from "@dariosoft/components";
import React, { useEffect, useState } from "react"
import { useSelector } from 'react-redux';

const $: any = (window as any).$;

export type TNavbarProps = {
    sidebarId?: string
}

export function Navbar(props: TNavbarProps) {
    const userInfo = AuthService.instance.session.getInfo();

    return <nav className="navbar main-navbar row">
        <div className="col-9">
            {!String.isEmpty(props.sidebarId) && <ToggleSidebar sidebarId={props.sidebarId!} />}
            &nbsp;<Breadcrumbs />
        </div>
        <div className="col-3">
            {/* <div className="d-flex justify-content-end">
                <span>{userInfo.username}</span>
                &nbsp;|&nbsp;
                <SignoutButton />
            </div> */}
        </div>
    </nav>


}

const SignoutButton = function () {
    return <a onClick={() => AuthService.instance.session.signOut()} href="#" className="link-danger"><i className="bi bi-box-arrow-right"></i>&nbsp;{I18n.translates.Signout}</a>
}
    , ToggleSidebar = function (props: { sidebarId: string }) {
        const service = MainLayoutService.instance
            , sidebarVisible = useSelector(service.getSidebarVisible)
            , sidebarWidth = useSelector(service.getSidebarWidth)

        const toggle = (e: React.MouseEvent<HTMLElement>) => {
            e.preventDefault();

            if (!String.isEmpty(props.sidebarId)) {
                $(`#${props.sidebarId}`)?.animate({ width: sidebarVisible ? '0' : sidebarWidth });
                service.setSidebarVisible(!sidebarVisible);
            }
        }

        return <a title={I18n.translates.ToggleMenu} onClick={toggle} href='#'><i className="bi bi-list"></i></a>;
    }