import { Icons } from "@app/core";
import { AuthService, MainLayoutService } from "@app/services";
import { Sidebar, TSidebarItem } from "@dariosoft/components";
import { useMemo } from "react";
import { useSelector } from "react-redux";

export function MainMenu(props: { id: string }) {
    const layoutService = MainLayoutService.instance,
        sidebarWidth = useSelector(layoutService.getSidebarWidth),
        currentUser = useMemo(AuthService.instance.session.getInfo, []),
        items: TSidebarItem[] = useMemo(() => {
            return [
                {
                    id: 'item-current-user',
                    title: String.coalesce(currentUser.displayName, currentUser.username, currentUser.email)!,
                    icon: Icons.user,
                    expanded: true,
                    subItems: [
                        {
                            id: 'item-settings-user',
                            title: I18n.translates.UserProfile,
                            icon: Icons.userCheck,
                            actionLink: '#/user-profile',
                        },
                        {
                            id: 'item-signout',
                            title: I18n.translates.Signout,
                            icon: Icons.signout,
                            actionLink: () => AuthService.instance.session.signOut()
                        }
                    ]
                },
                {
                    id: 'item-home',
                    title: I18n.translates.HomePage,
                    icon: Icons.home,
                    actionLink: '#/',
                },
                {
                    id: 'item-clients',
                    title: I18n.translates.Clients,
                    icon: Icons.clients,
                    actionLink: '#/clients',
                },
                {
                    id: 'item-settings',
                    title: I18n.translates.Settings,
                    icon: Icons.settings,
                    subItems: [

                        {
                            id: 'item-settings-email_sender',
                            title: I18n.translates.SmtpSettings,
                            icon: Icons.emailSettings,
                            actionLink: '#/smtp-settings',
                        },
                        {
                            id: 'item-settings-forgot-password',
                            title: I18n.translates.Forgot_Password,
                            icon: Icons.forgotPasswordSettings,
                            actionLink: '#/forgot-password-settings',
                        }
                    ]
                },

            ]
        }, []);

    return <Sidebar id={props.id} title="Template Service" width={sidebarWidth} items={items} />
}