import { createAsyncThunk } from "@reduxjs/toolkit";
import { TChangePasswordDto, TForgotPasswordStartModel, TForgotPasswordResult, TLoginDto, TLoginResult, TUpdateEmailModel, TForgotPasswordVerifyModel, TForgotPasswordResetModel } from "../types/auth-types";
import { ApiClient } from "./___ApiClient";
import { StoreManager } from "@app/core";

export class ForgotPasswordApi extends ApiClient {
    private constructor() {
        super('auth/api/v1/forgot-password')
    }

    //#region PUBLICS
    public static get instance(): ForgotPasswordApi {
        if (ForgotPasswordApi._instance == null) ForgotPasswordApi._instance = new ForgotPasswordApi();
        return ForgotPasswordApi._instance!;
    }

    public start = {
        thunk: createAsyncThunk('auth/forgot/start', (payload: TForgotPasswordStartModel) => this.api.start(payload)),
        submit: (payload: TForgotPasswordStartModel) => StoreManager.dispatch(this.start.thunk(payload) as any)
    }
    public verify = {
        thunk: createAsyncThunk('auth/forgot/verify', (payload: TForgotPasswordVerifyModel) => this.api.verify(payload)),
        submit: (payload: TForgotPasswordVerifyModel) => StoreManager.dispatch(this.verify.thunk(payload) as any)
    }
    public resetPassword = {
        thunk: createAsyncThunk('auth/forgot/reset', (payload: TForgotPasswordResetModel) => this.api.resetPassword(payload)),
        submit: (payload: TForgotPasswordResetModel) => StoreManager.dispatch(this.resetPassword.thunk(payload) as any)
    }

    //#endregion

    //#region PRIVATES
    private static _instance: ForgotPasswordApi | null = null;
    private readonly api = {
        start: (payload: TForgotPasswordStartModel): Promise<IResult<TForgotPasswordResult>> => this.http.postJson(`start`, payload),
        verify: (payload: TForgotPasswordVerifyModel): Promise<IResult<TForgotPasswordResult>> => this.http.postJson(`verify`, payload),
        resetPassword: (payload: TForgotPasswordResetModel): Promise<IResult> => this.http.postJson(`reset-password`, payload),
    }
    //#endregion
}