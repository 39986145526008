import { SettingsService } from '@app/services';
import './style.scss';
import { useSelector } from 'react-redux';
import { useEffect } from 'react';
import { TForm, TextInput } from '@dariosoft/components';

export function ForgotPasswordSettings() {
    const service = SettingsService.instance.forgotPassword,
        loading = useSelector(SettingsService.instance.getLoading),
        model = useSelector(service.getModel),
        otherButtons = <>
            <button onClick={service.load} className='btn btn-sm btn-primary' type='button'>{I18n.translates.Refresh}</button>
        </>
        ;

    useEffect(() => {
        setTimeout(service.load, 50);
    }, []);

    return <div className="vw vw-forgot-password-settings">
        <TForm id='formForgotPasswordSettings' onSubmit={service.update} otherButtons={otherButtons} loading={loading} submitText={I18n.translates.Update} lastTabIndex={2}>
            <TextInput id='txtTemplatePath' label={I18n.translates.TemplatePath} value={model.template} onChanged={val => service.setModel('template', val ?? '')} tabIndex={1} maxLength={255} autoFocus />
            <TextInput id='txtMailSubject' label={I18n.translates.EmailSubject} value={model.emailSubject} onChanged={val => service.setModel('emailSubject', val ?? '')} tabIndex={2} maxLength={255} />
        </TForm>
    </div>
}