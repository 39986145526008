export { };

declare global {
    const darioCrypto: {
        sha1(plainText: string): Promise<string>,
        sha256(plainText: string): Promise<string>,
        sha512(plainText: string): Promise<string>,
        generateRandomSecretKey(): Promise<string>,
        encrypt: (plainText: string, key: string) => Promise<string>,
        decrypt: (cipherText: string, key: string) => Promise<string>,
        lock: (plainText: string) => Promise<string>,
        unlock: (lockedText: string) => Promise<string>
    }
}

let win: any = window;

type THashAlgorithm = 'SHA-1' | 'SHA-256' | 'SHA-384' | 'SHA-512';
type TEncryptionAlgorithm = 'AES-CBC';


const unit8Helper = (function () {
    const textEncoder = new TextEncoder(), textDecoder = new TextDecoder();

    return Object.freeze({
        strToUint8: (input: string): Uint8Array => textEncoder.encode(input),
        unit8ToStr: (input: Uint8Array): string => textDecoder.decode(input),
        hexToUint8: (hexString: string): Uint8Array => {
            let items = hexString.match(/.{1,2}/g) ?? [];
            const encryptedArray = items.map(byte => parseInt(byte, 16));
            return new Uint8Array(encryptedArray);
        },
        unit8ToHex: (input: Uint8Array): string => {
            const hex = Array.from(input).map(b => b.toString(16).padStart(2, '0')).join('');
            return hex;
        },
        empty: (len: number): Uint8Array => new Uint8Array(new Array(len).fill(0))
    });
})();


win.darioCrypto = (function () {
    const aesAlgorithmIdentifier = { name: 'AES-CBC', iv: unit8Helper.empty(16), length: 256 },
        hash = (alg: THashAlgorithm, plainText: string): Promise<string> => {
            return crypto.subtle
                .digest(alg, unit8Helper.strToUint8(plainText))
                .then(buff => unit8Helper.unit8ToHex(new Uint8Array(buff)));
        },
        hashBuffer = (alg: THashAlgorithm, plainText: string): Promise<ArrayBuffer> => crypto.subtle.digest(alg, unit8Helper.strToUint8(plainText)),
        importKeyFromString = async (keyString: string, algorithm: TEncryptionAlgorithm) => {
            const keyBuffer = await hashBuffer('SHA-256', keyString);
            const key = await window.crypto.subtle.importKey(
                'raw', // raw format of the key - should be an ArrayBuffer
                keyBuffer,
                {
                    name: algorithm,
                    length: 256 // key length should match the algorithm's requirement
                },
                false, // whether the key is extractable (i.e., can be used in exportKey)
                ['encrypt', 'decrypt'] // what this key can be used for
            );
            return key;
        }


    const context = {
        sha1: (plainText: string): Promise<string> => hash('SHA-1', plainText),
        sha256: (plainText: string): Promise<string> => hash('SHA-256', plainText),
        sha512: (plainText: string): Promise<string> => hash('SHA-512', plainText),
        encrypt: async (plainText: string, key: string): Promise<string> => {
            try {
                const _key = await importKeyFromString(key, 'AES-CBC');
                const encryptedContent = await crypto.subtle.encrypt(aesAlgorithmIdentifier, _key, unit8Helper.strToUint8(plainText));
                return unit8Helper.unit8ToHex(new Uint8Array(encryptedContent));
            }
            catch (err) {
                console.error('darioCrypto.encrypt failed.', err);
                return '';
            }

        },
        decrypt: async (cipherText: string, key: string): Promise<string> => {
            try {
                const _key = await importKeyFromString(key, 'AES-CBC');
                const decryptedContent = await crypto.subtle.decrypt(aesAlgorithmIdentifier, _key, unit8Helper.hexToUint8(cipherText));
                return unit8Helper.unit8ToStr(new Uint8Array(decryptedContent));
            }
            catch (err) {
                console.error('darioCrypto.decrypt failed.', err);
                return '';
            }
        },
        generateRandomSecretKey: (): Promise<string> => {
            return Promise.all([hash('SHA-1', crypto.randomUUID()), hash('SHA-1', crypto.randomUUID())]).then(results => results.join(''));
        },
        lock: (plainText: string): Promise<string> => {
            let a = randomTextGenerator.generateRandomNumber(4, 'skipZeroFromStart'),
                b = randomTextGenerator.generateRandomNumber(4),
                key = a + b;


            return context.encrypt(plainText, key).then(cipherText => {
                return a + cipherText + b;
            });
        },
        unlock: (lockedText: string): Promise<string> => {
            if (String.isEmpty(lockedText)) return Promise.resolve('');
            let a = lockedText.slice(0, 4), b = lockedText.slice(-4), cipherText = lockedText.slice(4, -4);
            return context.decrypt(cipherText, a + b);
        }
    };

    return Object.freeze(context);
})();