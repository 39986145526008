import './style.scss'
import { Icons, RouteManager } from "@app/core";
import { AuthService, TemplateService } from "@app/services";
import { templateTypes } from "@app/types";
import { ActionLink, CardView, Checkbox, FieldView, Offcanvas, PillBadge, StrSelect, SubLayout, TCardItem, TForm, TSortOption, TextArea, TextInput, offcanvasApi } from "@dariosoft/components";
import { useEffect } from "react";
import { useSelector } from "react-redux";
import { useParams } from "react-router-dom";

export function ClientTemplates() {
    return <SubLayout layoutRouteMatch='clients/:id/templates'>
        <TemplateList />
    </SubLayout>
}

export function TemplateList() {
    const { clientId } = useParams()
        , editorPaneId = 'editor-pane'
        , service = TemplateService.instance
        , clientInfo = useSelector(service.getClientInfo)
        , autoGeneratePath = useSelector(service.getAutoGeneratePath)
        , model = useSelector(service.editor.getModel)
        , loading = useSelector(service.getLoading)
        , addons = <>

            <ActionLink onClick={service.list.loadList} title={I18n.translates.Refresh} css="btn btn-sm btn-outline-dark" icon={Icons.refresh} />&nbsp;
            <ActionLink onClick={() => { offcanvasApi.show(editorPaneId) }} title={I18n.translates.Add} css="btn btn-sm btn-outline-dark" icon={Icons.add} />

            {Boolean(clientInfo) && <div className='client-info'> <b>{I18n.translates.Client}:</b> {clientInfo!.name}</div>}
        </>
        , onEditorShown = () => { tryFocus('#txtName'); }
        , sortOptions: Array<TSortOption> = [
            { field: 'name', label: 'Sort by Name' },
            { field: 'createdAt', label: 'Sort by Creation Time' },
            { field: 'enabled', label: 'Sort by Enable' }
        ],
        carViewFormat = (e: templateTypes.TTemplateModel): TCardItem<templateTypes.TTemplateModel> => ({
            data: e,
            id: e.id,
            header: e.name,
            body: {
                description: e.description,
            },
            list: [
                { label: I18n.translates.CreatedAt, value: e.createdAt },
                { label: I18n.translates.Language, value: e.lang },
                { label: I18n.translates.Version, value: e.version },
                { label: I18n.translates.Path, value: e.path },
                { label: I18n.translates.Enabled, value: <PillBadge value={e.enabled} mode={e.enabled ? 'success' : 'danger'} /> },
            ],
            actions: [
                { icon: Icons.edit, css: 'text-body', text: I18n.translates.Edit, action: e => service.editor.edit(e.data) },
                { icon: `${Icons.trash} text-danger`, css: 'text-body', text: I18n.translates.Delete, action: e => service.list.remove(e.data) },
                { icon: Icons.templateContext, css: 'text-body', text: I18n.translates.Context, link: e => `#clients/${e.data.ownerId}/templates/${e.id}/context` },
                { icon: Icons.preview, css: 'text-body', text: I18n.translates.Preview, linkTarget: '_blank', link: e => `/admin/api/v1/template/preview/${e.id}?t=Bearer ${AuthService.instance.session.getToken()}` },
            ]
        });

    useEffect(() => {
        if (!Guid.isGuid(clientId)) return RouteManager.navigateTo.notFound();
        service.events.onInitizlized(e => {
            if (!Boolean(e.detail.clientInfo) && !loading) return RouteManager.navigateTo.notFound();
        });

        service.reset(clientId!);
        service.events.onBeginEdit(e => { offcanvasApi.show(editorPaneId) });
        service.events.onEditReset(e => { offcanvasApi.hide(editorPaneId) });
        service.events.onAfterSubmit(e => { offcanvasApi.hide(editorPaneId) });

        return service.close;
    }, []);


    return <div className='vw vw-template-list'>
        <Offcanvas id={editorPaneId} header={I18n.translates.Editor} onShown={onEditorShown}>
            <TForm id="template-editor" onSubmit={service.editor.submit} onReset={service.editor.reset} lastTabIndex={7} loading={loading} resetButtonVisible={true}>
                <TextInput id="txtName" label={I18n.translates.Name} value={model.name} onChanged={val => service.editor.setModel('name', val ?? '')} maxLength={64} tabIndex={1} autoFocus />
                <StrSelect id="txtLang" label={I18n.translates.Language} items={service.languages} value={model.lang} onChanged={e => service.editor.setModel('lang', e as any)} tabIndex={2} />
                <TextInput id="txtVersion" label={I18n.translates.Version} value={model.version} onChanged={val => service.editor.setModel('version', val || '')} maxLength={10} tabIndex={3} />
                <Checkbox id='chkAutoGeneratePath' label={I18n.translates.AutoGeneratePath} value={autoGeneratePath} onChanged={service.setAutoGeneratePath} tabIndex={4} />
                <TextInput id="txtPath" label={I18n.translates.Path} value={model.path} onChanged={val => service.editor.setModel('path', val || '')} readonly={autoGeneratePath} maxLength={255} tabIndex={5} />
                <TextArea id="txtDescription" label={I18n.translates.Description} value={model.description} onChanged={val => service.editor.setModel('description', val)} maxLength={255} tabIndex={6} optional />
                <Checkbox id="chkEnabled" label={I18n.translates.Active} value={model.enabled} onChanged={val => service.editor.setModel('enabled', val)} tabIndex={7} />
            </TForm>
        </Offcanvas>
        <CardView
            rowCols={{ md: 3, xxl: 4 }}

            context={service.list}
            format={carViewFormat}
            searchBoxVisible={true}
            sortOptions={sortOptions}
            extensions={addons}
        />
    </div>
}