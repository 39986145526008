import { createAsyncThunk } from "@reduxjs/toolkit";
import { TChangePasswordDto, TForgotPasswordStartModel, TForgotPasswordResult, TLoginDto, TLoginResult, TUpdateEmailModel, TForgotPasswordVerifyModel, TForgotPasswordResetModel } from "../types/auth-types";
import { ApiClient } from "./___ApiClient";
import { StoreManager } from "@app/core";

export class UserApi extends ApiClient {
    private constructor() {
        super('auth/api/v1/user')
    }

    //#region PUBLICS
    public static get instance(): UserApi {
        if (UserApi._instance == null) UserApi._instance = new UserApi();
        return UserApi._instance!;
    }

    public login = {
        thunk: createAsyncThunk('auth/login', (payload: TLoginDto) => this.api.login(payload)),
        submit: (payload: TLoginDto) => StoreManager.dispatch(this.login.thunk(payload) as any)
    }
    public logout = {
        thunk: createAsyncThunk('auth/logout', () => this.api.logout()),
        submit: () => StoreManager.dispatch(this.logout.thunk() as any)
    }
    public changePassword = {
        thunk: createAsyncThunk('auth/update-password', (payload: TChangePasswordDto) => this.api.changePassword(payload)),
        submit: (payload: TChangePasswordDto) => StoreManager.dispatch(this.changePassword.thunk(payload) as any)
    }

    public updateEmail = {
        thunk: createAsyncThunk('auth/update-email', (payload: TUpdateEmailModel) => this.api.updateEmail(payload)),
        submit: (payload: TUpdateEmailModel) => StoreManager.dispatch(this.updateEmail.thunk(payload) as any)
    }
    //#endregion

    //#region PRIVATES
    private static _instance: UserApi | null = null;
    private readonly api = {
        login: (payload: TLoginDto): Promise<IResult<TLoginResult>> => this.http.postJson(`login`, payload),
        logout: (): Promise<IResult> => this.http.get(`logout`),
        changePassword: (payload: TChangePasswordDto): Promise<IResult> => this.http.postJson(`change-password`, payload),
        updateEmail: (payload: TUpdateEmailModel): Promise<IResult> => this.http.postJson(`update-email`, payload),
    }
    //#endregion
}