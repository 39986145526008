import { TChangePasswordDto, TLoginDto, TLoginResult } from '@app/types';
import { Draft, PayloadAction, Slice } from "@reduxjs/toolkit";
import { StoreManager, EventService } from "@app/core";
import { UserApi } from '../api/user-api';
import { SessionManager } from '../session-manager';
import { MessageBox } from '@dariosoft/components';

type TState = {
    loading: boolean,
    hash: string,
    loginModel: TLoginDto,
    changePassword: {
        model: TChangePasswordDto,
        confirmPasswordErrors: string[],
    }
}

type TDraft = Draft<TState>;
type TReducers = {
    setLoginValue: <TKey extends keyof TLoginDto>(state: TDraft, action: PayloadAction<{ key: TKey, value: TLoginDto[TKey] }>) => void,
    setChangePasswordValue: <TKey extends keyof TChangePasswordDto>(state: TDraft, action: PayloadAction<{ key: TKey, value: TChangePasswordDto[TKey] }>) => void,
    resetChangePasswordForm: (state: TDraft) => void,
    setHash: (state: TDraft, action: PayloadAction<string>) => void
};


export class AuthService {
    private constructor() {
        let store = StoreManager.createSlice<TState, TReducers>({
            name: 'services/auth-service',
            initialState: {
                loading: false,
                loginModel: { username: 'suadmin', password: '123456++', securityToken: '' },
                changePassword: {
                    model: { currentPassword: '', newPassword: '', confirmPassword: '' },
                    confirmPasswordErrors: []
                },
                hash: ''
            },
            reducers: {
                setLoginValue: <TKey extends keyof TLoginDto>(state: TDraft, action: PayloadAction<{ key: TKey, value: TLoginDto[TKey] }>) => {
                    state.loginModel[action.payload.key] = action.payload.value;
                },
                setChangePasswordValue: <TKey extends keyof TChangePasswordDto>(state: TDraft, action: PayloadAction<{ key: TKey, value: TChangePasswordDto[TKey] }>) => {
                    state.changePassword.model[action.payload.key] = action.payload.value;

                    if (state.changePassword.model.newPassword === state.changePassword.model.confirmPassword)
                        state.changePassword.confirmPasswordErrors.clear();
                    else
                        state.changePassword.confirmPasswordErrors.push(I18n.translates.MSG_Warn_NewPasswordAndConfirmPasswordAreNotSame);

                },
                resetChangePasswordForm: (state: TDraft) => {
                    state.changePassword.model.currentPassword = '';
                    state.changePassword.model.newPassword = '';
                    state.changePassword.model.confirmPassword = '';
                    state.changePassword.confirmPasswordErrors.clear();
                },
                setHash: (state: TDraft, action: PayloadAction<string>) => {
                    state.hash = action.payload
                }
            },
            extraReducers: (builder) => {
                builder
                    .addCase(UserApi.instance.login.thunk.pending, state => { state.loading = true })
                    .addCase(UserApi.instance.login.thunk.rejected, state => { state.loading = false })
                    .addCase(UserApi.instance.login.thunk.fulfilled, (state, action) => {
                        state.loading = false;
                        if (action.payload.isSuccessful && action.payload.data) {
                            state.loginModel.password = '';
                            SessionManager.instance.persits(action.payload.data);
                            EventService.instance.userSignedIn.dispatch(action.payload.data);
                        }
                    })

                    .addCase(UserApi.instance.logout.thunk.pending, state => { state.loading = true })
                    .addCase(UserApi.instance.logout.thunk.rejected, state => { state.loading = false })
                    .addCase(UserApi.instance.logout.thunk.fulfilled, (state, action) => {
                        state.loading = false;
                        SessionManager.instance.clear();
                        EventService.instance.userSignedOut.dispatch();
                    })

                    .addCase(UserApi.instance.changePassword.thunk.pending, state => { state.loading = true })
                    .addCase(UserApi.instance.changePassword.thunk.rejected, state => { state.loading = false })
                    .addCase(UserApi.instance.changePassword.thunk.fulfilled, (state, action) => {
                        state.loading = false;
                        if (action.payload.isSuccessful) {
                            state.changePassword.model.currentPassword = '';
                            state.changePassword.model.newPassword = '';
                            state.changePassword.model.confirmPassword = '';
                            MessageBox.toast.passwordUpdatedSuccessfully();
                        }
                    });
            }
        });

        this.slice = store[0];
        this.getState = store[1];
    }

    //#region public
    public static get instance(): AuthService {
        if (AuthService._instance == null)
            AuthService._instance = new AuthService();

        return AuthService._instance!;
    }


    public getLoading = () => this.getState().loading;

    public readonly login = {
        getModel: () => this.getState().loginModel,
        setUserName: (val: string | undefined) => StoreManager.dispatch(this.slice.actions.setLoginValue({ key: 'username', value: val ?? '' })),
        setPassword: (val: string | undefined) => StoreManager.dispatch(this.slice.actions.setLoginValue({ key: 'password', value: val ?? '' })),
        submit: async () => {
            let model = { ...this.getState().loginModel };
            model.password = await darioCrypto.lock(model.password);
            UserApi.instance.login.submit(model)
        }
    }

    public readonly changePassword = {
        getModel: () => this.getState().changePassword.model,
        setModel: <K extends keyof TChangePasswordDto>(key: K, value: TChangePasswordDto[K]) => StoreManager.dispatch(this.slice.actions.setChangePasswordValue({ key: key, value: value })),
        validateConfirmPassword: (): string[] => this.getState().changePassword.confirmPasswordErrors,
        reset: () => {
            StoreManager.dispatch(this.slice.actions.resetChangePasswordForm());
        },
        submit: async () => {
            let model = { ...this.getState().changePassword.model };
            let [currentPassword_locked, newPassword_locked, confirmPassword_locked] = await Promise.all([darioCrypto.lock(model.currentPassword), darioCrypto.lock(model.newPassword), darioCrypto.lock(model.confirmPassword)]);
            model.currentPassword = currentPassword_locked;
            model.newPassword = newPassword_locked;
            model.confirmPassword = confirmPassword_locked;
            UserApi.instance.changePassword.submit(model);
        }
    }

    public readonly session = {
        getUserIsAuthenticated: (): boolean => SessionManager.instance.hasInfo,
        getInfo: (): TLoginResult => SessionManager.instance.getInfo(),
        signOut: () => UserApi.instance.logout.submit(),
        getToken: () => SessionManager.instance.getToken()
    }

    public reset = (): void => {
        this.changePassword.reset();
    }
    //#endregion

    //#region privates
    private static _instance: AuthService | null = null;
    private readonly getState!: () => TState;
    private readonly slice!: Slice<TDraft, TReducers>;
    //#endregion
}
