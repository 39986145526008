export { };

declare global {
    type EnvironmentName = 'development' | 'test' | 'staging' | 'production';
    function getCurrentEnvironment(): EnvironmentName;
    function waitForDelay(milliseconds: number): Promise<void>;
    function getTypeName(x: any): TType;
    function createOfType(typename: TType): any;
    function isPlainObject(x: any): boolean;
    function isFunc(x: any): boolean;
    function isArray(x: any): boolean;
    function isDate(x: any): boolean;
    function isBoolean(x: any): boolean;
    function isNumber(x: any): boolean;
    function isString(x: any): boolean;
    function tryFocus(selector: string): boolean;
    function deepCopy<T extends [] | TPlainObject>(input: T): T;
    function patchObject<T extends TPlainObject>(target: T, source: TPlainObject, deepPatch?: boolean): T;
    function serializeFlatObjToQueryString(obj: TPlainObject): string;
    function formatDateProperty(obj: TPlainObject, format: string, keys: string[]): void;
    function passwordIsComplex(password: string, minLen: number, options?: { letterAndDigitMixed: boolean, differentCharCase: boolean }): boolean;

    const Guid: {
        isGuid: (id: string | undefined | null) => boolean,
        newId: (format?: string) => string,
        empty: (format?: string) => string,
        isEmpty: (id: string | null | undefined) => boolean,
        emptyId: string
    }

    const EnumHelper: {
        getNames: (e: any) => Array<string>,
        getValues: (e: any) => Array<number>,
        getNameMap: (e: any) => { [name: string]: number },
        getValueMap: (e: any, skipTranslate?: boolean) => { [value: number]: string },
        getText: (e: any, val: number | string) => string
    };
}

const win: any = window;

win.waitForDelay = (milliseconds: number) => new Promise<void>(resolve => setTimeout(resolve, milliseconds));

win.getCurrentEnvironment = function (): EnvironmentName {
    switch ((process.env.NODE_ENV ?? '').toLowerCase().trim()) {
        case 'dev':
        case 'development': return 'development';

        case 'test': return 'test';

        case 'demo':
        case 'staging': return 'staging';

        default: return 'production';
    }
}

const getTypeName = win.getTypeName = (x: any): TType => {
    let typename = Object.prototype.toString.call(x).slice(8, -1) as TType;
    return typename;
}

const createOfType = win.createOfType = (typename: TType): any => {
    if (typename === 'Undefined') return;
    if (typename === 'Null') return null;
    return eval(`${typename}.prototype.constructor.call()`);
}

win.isPlainObject = (x: any): boolean => getTypeName(x) === 'Object';
win.isFunc = (x: any): boolean => getTypeName(x) === 'Function';
win.isArray = (x: any): boolean => getTypeName(x) === 'Array';
win.isDate = (x: any): boolean => getTypeName(x) === 'Date';
win.isBoolean = (x: any): boolean => getTypeName(x) === 'Boolean';
win.isNumber = (x: any): boolean => getTypeName(x) === 'Number';
win.isString = (x: any): boolean => getTypeName(x) === 'String';
win.formatDateProperty = (obj: TPlainObject, format: string, keys: string[]): void => {
    if (!obj || String.isEmpty(format) || !isArray(keys) || keys.length == 0) return;


    keys.forEach(key => {
        if (Object.hasOwn(obj, key)) {
            try { obj[key] = new Date(obj[key]?.toString() ?? '').format(format); } catch { }
        }
    });
}
win.passwordIsComplex = (password: string, minLen: number, options?: { letterAndDigitMixed: boolean, differentCharCase: boolean }): boolean => {
    return !String.isEmpty(password) &&
        password.length >= minLen &&
        (
            !isPlainObject(options) ||
            (
                (!Boolean(options!.letterAndDigitMixed) || (/\d/.test(password) && /\D/.test(password))) &&
                (!Boolean(options!.differentCharCase) || (/[a-z]/.test(password) && /[A-Z]/.test(password)))
            )

        );
}

win.tryFocus = (selector: string): boolean => {
    try {
        let el: any = document.querySelector(selector);
        if (el && el.focus instanceof Function) {
            el.focus();
            return true;
        }
        return false;
    }
    catch {
        return false;
    }
}

win.serializeFlatObjToQueryString = (obj: TPlainObject): string => {
    if (!isPlainObject(obj)) return '';
    return Object.keys(obj)
        .map(key => ({ key: key, val: obj[key]?.toString() }))
        .filter(e => !String.isEmpty(e.val))
        .map(e => `${encodeURIComponent(e.key)}=${encodeURIComponent(e.val)}`)
        .join('&');
}

win.Guid = (function () {
    const _empty = '00000000-0000-0000-0000-000000000000',
        _hex = '0123456789abcdef',
        _randomInt = function () { return Math.floor(0x100000000 * Math.random()); },
        _uuid = function (w1: number, w2: number, w3: number, w4: number, version?: 1 | 2 | 3 | 4 | 5) {
            var uuid = new Array(36);
            var data = [
                (w1 & 0xFFFFFFFF),
                (w2 & 0xFFFF0FFF) | ((version || 4) << 12), // version (1-5)
                (w3 & 0x3FFFFFFF) | 0x80000000,    // rfc 4122 variant
                (w4 & 0xFFFFFFFF)
            ];
            for (var i = 0, k = 0; i < 4; i++) {
                var rnd = data[i];
                for (var j = 0; j < 8; j++) {
                    if (k == 8 || k == 13 || k == 18 || k == 23) {
                        uuid[k++] = '-';
                    }
                    var r = (rnd >>> 28) & 0xf; // Take the high-order nybble
                    rnd = (rnd & 0x0FFFFFFF) << 4;
                    uuid[k++] = _hex.charAt(r);
                }
            }
            return uuid.join('');
        },
        _format = (value: string, format?: string) => {
            switch (format) {
                case 'N': return value.replace(/-/g, '');
                case 'B': return '{' + value + '}';
                case 'P': return '(' + value + ')';
                default: return value;
            }
        }

    return {
        isGuid: (id: string | undefined | null) => !String.isEmpty(id) && /^[a-f0-9]{8}-[a-f0-9]{4}-[a-f0-9]{4}-[a-f0-9]{4}-[a-f0-9]{12}$/i.test(id!),
        newId: (format?: string) => _format(_uuid(_randomInt(), _randomInt(), _randomInt(), _randomInt(), 4), format),
        empty: (format?: string) => _format(_empty, format),
        emptyId: _empty,
        isEmpty: (id: string | null | undefined) => String.isEmpty(id) || id == _empty
    }

}());

win.EnumHelper = (function () {
    const getTranslate = (e: any, val: number | string) => {
        let key = Object.keys(e).find(k => k.startsWith('$__ENUMNAME__'));
        if (String.isEmpty(key)) return '';
        key = key!.substring(13);
        return I18n.enumTranslates[`ENUM_${key}_${val}`];
    };

    let context = {
        getNames: (e: any) => Object.keys(e).filter(k => /^[a-z]([a-z0-9_]+)?$/i.test(k)),
        getValues: (e: any) => Object.keys(e).filter(k => /^\d+$/.test(k)).map(k => +k),
        getNameMap: (e: any) => {
            let map: { [key: string]: number } = {};
            context.getNames(e).forEach((name: string) => map[name] = +e[name]);
            return map;
        },
        getValueMap: (e: any, skipTranslate?: boolean) => {
            let map: { [key: number]: string } = {};
            if (skipTranslate)
                context.getValues(e).forEach((val: number) => map[val] = e[val]);
            else
                context.getValues(e).forEach((val: number) => map[val] = getTranslate(e, e[val]));


            return map;
        },
        getText: (e: any, val: number | string) => getTranslate(e, val)
    };

    return context;
})();

win.deepCopy = <T extends [] | TPlainObject>(input: T): T => {
    return JSON.parse(JSON.stringify(input));
}

win.patchObject = <T extends TPlainObject>(dest: T, src: TPlainObject, deepPatch?: boolean): T => {
    const patch = (target: T, source: TPlainObject, deep: boolean) => {
        if (source === null || source === undefined) return target;

        let result: any = isPlainObject(target) ? { ...source, ...target } : { ...source };

        //Removing redundant keys
        let sourceKeys = Object.keys(source);
        Object.keys(result).forEach(key => { if (!sourceKeys.some(k => k === key)) delete result[key]; });

        // Type checking and patch children
        if (deep) {
            sourceKeys.forEach(key => {
                let srcType = getTypeName(source[key]);
                if (srcType !== getTypeName(result[key])) result[key] = createOfType(srcType);

                if (isPlainObject(source[key])) result[key] = patch(result[key], source[key], deep);
                if (isArray(source[key])) {
                    for (var i = 0; i < source[key].length; i++)
                        result[key][i] = patch(result[key][i], source[key][i], deep);
                }
            });
        }
        else {
            sourceKeys.forEach(key => {
                let srcType = getTypeName(source[key]);
                if (srcType !== getTypeName(result[key]))
                    result[key] = createOfType(srcType);
            })
        }

        return result;
    }

    return patch(dest, JSON.parse(JSON.stringify(src)), deepPatch ?? false);

}
