import './style.scss';

export type TModalButton = {
    key: string;
    text: string;
    css?: string;
};

export type TModalOptions = {
    dialogId?: string;
    title?: string;
    content: string;
    buttons: Array<TModalButton>
};

export const DarioModal = (function () {
    const buildHeader = (dlg: HTMLDialogElement, options: TModalOptions) => {
        if ((options.title || '').toString().trim() == '') return;
        let header = document.createElement('header');
        header.innerHTML = options.title ?? '';
        dlg.appendChild(header);
    },
        buildBody = (dlg: HTMLDialogElement, options: TModalOptions) => {
            let section = document.createElement('section');
            section.innerHTML = options.content;
            dlg.appendChild(section);
        },
        buildFooter = (dlg: HTMLDialogElement, options: TModalOptions) => {
            options.buttons = options.buttons instanceof Array ? options.buttons : [];
            if (options.buttons.length < 1) return;

            let footer = document.createElement('footer');

            footer.innerHTML = options.buttons.map(btn => `<button onclick="window['${options.dialogId}-close-fn'](event)" data-key="${btn.key}" class="${btn.css}">${btn.text}</button>`).join('&nbsp;');

            dlg.appendChild(footer);

        };

        return {
            open: (options: TModalOptions) => {
                return new Promise<string>(resolve => {
                    options = options || {};
                    options.dialogId = `dario-dialog-${Guid.newId()}`;
        
                    let dlg : HTMLDialogElement = document.createElement('dialog', {});
                    dlg.setAttribute('class', 'dario-dialog');
                    dlg.setAttribute('id', options.dialogId);
                    
        
                    buildHeader(dlg, options);
                    buildBody(dlg, options);
                    buildFooter(dlg, options);
        
                    let win: any = window;

                    win[`${options.dialogId}-close-fn`] = (function (options: TModalOptions, $resolve: (value: string) => void, e : MouseEvent) {
                        let key = (e.target as HTMLElement).getAttribute('data-key') ?? '';
                        let _dlg = document.getElementById(options.dialogId!) as HTMLDialogElement;
                        dlg.close(key);
                        dlg.remove();
                        $resolve(key);
                    }).bind({}, options, resolve);
        
                    document.body.appendChild(dlg);
                    dlg.showModal();
                });
            }
        };
})();