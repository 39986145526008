export interface PasswordOptions {
    length?: number;
    includeUppercase?: boolean;
    includeLowercase?: boolean;
    includeNumbers?: boolean;
    includeSymbols?: boolean;

}

export const securityHelper = Object.freeze({
    minPasswordLen: 8,
    checkPasswordComplexity: (password: string): boolean => passwordIsComplex(password, securityHelper.minPasswordLen, { letterAndDigitMixed: true, differentCharCase: true }),
    generateRandomPassword: (options?: PasswordOptions): string => {
        const defaultOptions: PasswordOptions = {
            length: 10,
            includeUppercase: true,
            includeLowercase: true,
            includeNumbers: true,
            includeSymbols: true,
        };

        options = { ...defaultOptions, ...options };

        const characterSets: string[] = [];
        
        if (options.includeUppercase) characterSets.push('ABCDEFGHIJKLMNOPQRSTUVWXYZ');
        if (options.includeLowercase) characterSets.push('abcdefghijklmnopqrstuvwxyz');
        if (options.includeNumbers) characterSets.push('0123456789');
        if (options.includeSymbols) characterSets.push('!@#$%^&*()_+{}[]|;:,./?');

        if (characterSets.length === 0) {
            throw new Error('At least one character set must be included.');
        }

        const characters = characterSets.join('');
        let password = '';

        for (let i = 0; i < options.length!; i++) {
            const randomIndex = Math.floor(Math.random() * characters.length);
            password += characters[randomIndex];
        }

        return password;
    }
});

