export type TClientInfo = {
    id: string,
    enabled: boolean,
    adminEmail: string,
    name: string,
    description?: string,
}

export type TClientModel = TClientInfo & {
    createdAt: string,
    isSystem: boolean,
}

export type TClientCredentialInfo = {
    clientId: string,
    apiKey: string,
    secretKey: string,
    adminUsername: string,
    adminPassword: string,
}

export const createDefaultClientInfo = (): TClientInfo => ({
    id: '',
    enabled: false,
    adminEmail:'',
    name: '',
    description: ''
});