import { ActionReducerMapBuilder, AsyncThunk, CaseReducerActions, Draft, PayloadAction, Slice, SliceCaseReducers, UnknownAction } from "@reduxjs/toolkit";
import { AsyncThunkConfig } from "@reduxjs/toolkit/dist/createAsyncThunk";
import { IStoreManager } from "./store-manager";
import { EventCase, EventCaseT, TEventListener, TEventListenerT } from "@dariosoft/framework";
import { MessageBox } from "@dariosoft/components";
import { TAction, TActionT, TApiInput, TApiThunk } from "./service-common-types";

export type TModelProvider<T extends TUniqueItem> = () => T;

export type TCrudState<TModel extends TPlainObject, TListItem extends TUniqueItem, TParams extends TPlainObject> = {
    loading: boolean,
    model: TModel,
    params: TParams,
    listLoadModel: TListLoadModel,
    list: {
        editingId?: string,
        tempQuery?: string,
        totalCount: number,
        items: TListItem[],
        selectedItem?: TListItem | undefined,
    },
};


export type TCrudThunks<
    TModel extends TPlainObject,
    TListItem extends TUniqueItem,
    TParams extends TPlainObject> = {
        create: TApiThunk<IResult<TSubmitResultDto>, TModel, TParams>,
        update: TApiThunk<IResult, TModel, TParams>,
        delete: TApiThunk<IResult, TListItem, TParams>,
        list: TApiThunk<IListResult<TListItem>, TListLoadModel, TParams>
    };

export type TCrudEditor<TModel extends TPlainObject, TListItem extends TUniqueItem> = {
    getModel: () => TModel,
    setModel: <TKey extends keyof TModel>(key: TKey, value: TModel[TKey]) => void,
    isEditingMode: () => boolean,
    edit: (item: TListItem) => void,
    submit: () => void
    reset: () => void
}

type TResetModelOptions = { cancelEdit: boolean };

type TReducers<TModel extends TPlainObject, TListItem extends TUniqueItem, TParams extends TPlainObject> = {
    updateParams: TActionT<TCrudState<TModel, TListItem, TParams>, TParams>,
    setLoading: TActionT<TCrudState<TModel, TListItem, TParams>, boolean>,
    setListPageIndex: TActionT<TCrudState<TModel, TListItem, TParams>, number>,
    setListSortBy: TActionT<TCrudState<TModel, TListItem, TParams>, string>,
    setSort: TActionT<TCrudState<TModel, TListItem, TParams>, { field: string, desc: boolean }>,
    setListTempFilterQuery: TActionT<TCrudState<TModel, TListItem, TParams>, string>,
    acceptListTempFilterQuery: TAction<TCrudState<TModel, TListItem, TParams>>,
    setModel: TActionT<TCrudState<TModel, TListItem, TParams>, { key: keyof TModel, value: any }>,
    resetModel: TActionT<TCrudState<TModel, TListItem, TParams>, TResetModelOptions>,
    resetList: TAction<TCrudState<TModel, TListItem, TParams>>,
    editItem: TActionT<TCrudState<TModel, TListItem, TParams>, TListItem>,
    selectListItemById: TActionT<TCrudState<TModel, TListItem, TParams>, string>,
    selectListItemBySerial: TActionT<TCrudState<TModel, TListItem, TParams>, number>,
}

export type TCrudServiceOptions<
    TModel extends TPlainObject,
    TListItem extends TUniqueItem,
    TParams extends TPlainObject,
    TState extends TCrudState<TModel, TListItem, TParams>,
    TAdditionalReducers extends { [key: string]: TAction<TState> | TActionT<TState, any> }
> = {
    name: string,
    apis: TCrudThunks<TModel, TListItem, TParams>,
    additionalReducers?: TAdditionalReducers,
    getInitalState: () => TState,
    actionReducerMapBuilder?: (builder: ActionReducerMapBuilder<TState>) => void,
};


export abstract class CrudService<
    TModel extends TPlainObject,
    TListItem extends TUniqueItem,
    TParams extends TPlainObject,
    TState extends TCrudState<TModel, TListItem, TParams>,
    TAdditionalReducers extends { [key: string]: TAction<TState> | TActionT<TState, any> }
> {
    constructor(
        private readonly storeManager: IStoreManager,
        private readonly options: TCrudServiceOptions<TModel, TListItem, TParams, TState, TAdditionalReducers>
    ) {

        this.initalState = options.getInitalState();

        let store = storeManager.createSlice({ // <TState, TReducers<TModel, TListItem, TParams> & TAdditionalReducers>
            name: options.name,
            initialState: this.initalState,
            reducers: {
                ...options.additionalReducers,
                updateParams: (state, action: PayloadAction<TParams>) => {
                    let params: any = state.params;
                    Object.assign(params, action.payload ?? {});
                },
                setLoading: (state, action: PayloadAction<boolean>) => { state.loading = action.payload },
                setListPageIndex: (state, action: PayloadAction<number>) => { state.listLoadModel.pageIndex = action.payload },
                setListSortBy: (state, action: PayloadAction<string>) => {
                    state.listLoadModel.sortOrder = state.listLoadModel.sortBy == action.payload ? (state.listLoadModel.sortOrder == 'asc' ? 'desc' : 'asc') : 'asc';
                    state.listLoadModel.sortBy = action.payload;
                },
                setSort: (state, action: PayloadAction<{ field: string, desc: boolean }>) => {
                    state.listLoadModel.sortOrder = action.payload.desc ? 'desc' : 'asc';
                    state.listLoadModel.sortBy = action.payload.field;
                },
                setListTempFilterQuery: (state, action: PayloadAction<string>) => { state.list.tempQuery = action.payload },
                acceptListTempFilterQuery: (state) => { state.listLoadModel.query = state.list.tempQuery?.trim() },
                setModel: (state, action: PayloadAction<{ key: keyof TModel, value: any }>) => {
                    let model: any = state.model, payload = { key: action.payload.key, value: action.payload.value };
                    this.onModelChanging(state, model, payload);
                    model[action.payload.key] = payload.value;
                    this.onModelChanged(state, model, payload);
                },
                resetModel: (state, action: PayloadAction<TResetModelOptions>) => {
                    state.model = { ...this.initalState.model } as any;

                    if (action.payload.cancelEdit) {
                        state.list.editingId = undefined;
                    }

                    setTimeout(this._events.action.resetEditor.bind(this, action.payload.cancelEdit), 6);
                },
                resetList: (state) => {
                    if (!Guid.isEmpty(state.list.editingId)) state.model = this.initalState.model as any;
                    state.listLoadModel = this.initalState.listLoadModel;
                    state.list.tempQuery = '';
                    state.list.totalCount = 0;
                    state.list.editingId = undefined;
                    state.list.selectedItem = undefined;
                    this._events.onListReset.dispatch();
                },
                editItem: (state, action: PayloadAction<TListItem>) => {
                    state.model = action.payload as any;
                    state.list.editingId = action.payload.id;
                    setTimeout(this._events.action.beginEdit.bind(this, action.payload), 5);
                },
                selectListItemById: (state, action: PayloadAction<string>) => {
                    state.list.selectedItem = state.list.items.find(x => x.id === action.payload)
                },
                selectListItemBySerial: (state, action: PayloadAction<number>) => {
                    state.list.selectedItem = state.list.items.find(x => x.serial === action.payload)
                }
            },
            extraReducers: (builder) => {
                builder
                    .addCase(this.options.apis.list.pending, state => { state.loading = true; })
                    .addCase(this.options.apis.list.rejected, state => { state.loading = false; })
                    .addCase(this.options.apis.list.fulfilled, (state, action) => {
                        state.loading = false;
                        this.onListThunkFulFilled(state, action);
                        if (!Guid.isEmpty(state.list.editingId)) {
                            state.list.editingId = undefined;
                            state.model = this.initalState.model as any;
                        }
                        if (action.payload.isSuccessful) {
                            state.list.totalCount = action.payload.totalCount;
                            state.listLoadModel.pageSize = action.payload.pageSize;

                            action.payload.data = isArray(action.payload.data) ? action.payload.data! : [];
                            action.payload.data.forEach(e => {
                                formatDateProperty(e, 'dd-MMM-yyyy hh:mm', ['createdAt', 'timestamp', 'updatedAt']);
                            });
                            state.list.items = action.payload.data as any;
                            state.list.selectedItem = state.list.items[0];
                        }
                    })

                    .addCase(this.options.apis.create.pending, state => { state.loading = true; })
                    .addCase(this.options.apis.create.rejected, state => { state.loading = false; })
                    .addCase(this.options.apis.create.fulfilled, (state, action) => {
                        state.loading = false;
                        if (action.payload.isSuccessful && action.payload.data) {
                            MessageBox.toast.submitSuccessfull();
                            state.list.editingId = undefined;
                            let item: any = { ...deepCopy(state.model), ...action.payload.data };
                            formatDateProperty(item, 'dd-MMM-yyyy hh:mm', ['createdAt', 'timestamp', 'updatedAt']);
                            state.list.totalCount += 1;
                            state.list.items.unshift(item);
                            setTimeout(this._events.action.afterSubmit.bind(this, item, action.payload.data!), 20);
                        }
                    })

                    .addCase(this.options.apis.update.pending, state => { state.loading = true; })
                    .addCase(this.options.apis.update.rejected, state => { state.loading = false; })
                    .addCase(this.options.apis.update.fulfilled, (state, action) => {
                        state.loading = false;
                        if (action.payload.isSuccessful) {
                            MessageBox.toast.submitSuccessfull();
                            let item: any = state.list.items.find(x => x.id == state.list.editingId);
                            state.list.editingId = undefined;
                            if (item) {
                                let model: any = deepCopy(state.model);
                                delete model.id;
                                delete model.serial;
                                Object.assign(item, model);
                                setTimeout(this._events.action.afterSubmit.bind(this, item, action.payload.data!), 20);
                            }
                        }
                    })

                    .addCase(this.options.apis.delete.pending, state => { state.loading = true; })
                    .addCase(this.options.apis.delete.rejected, state => { state.loading = false; })
                    .addCase(this.options.apis.delete.fulfilled, (state, action) => {
                        state.loading = false;
                        if (action.payload.isSuccessful) {
                            let item = action.meta.arg.data;
                            let affecteds = state.list.items.removeWhere(x => x.id == item.id /*|| x.serial == item.serial*/)
                            state.list.totalCount -= affecteds;
                            //TODO: Recalculate paging(PageIndex, ...)
                        }
                    });

                if (options.actionReducerMapBuilder instanceof Function)
                    options.actionReducerMapBuilder(builder);
            }
        });

        this.crudSlice = store[0] as any;
        this.slice = store[0] as any;
        this.getState = store[1];

    }

    //#region PUBLICS
    public readonly getLoading = () => this.getState().loading;
    public readonly setLoading = (loading: boolean) => {
        if (this.getState().loading != loading)
            this.storeManager.dispatch(this.crudSlice.actions.setLoading(loading));
    }
    public readonly setParams = (params: TParams): void => this.dispatch(this.crudSlice.actions.updateParams(params), { reloadList: true });
    public readonly getSelectedItem = (): TListItem | undefined => this.getState().list.selectedItem;
    public readonly getBySerial = (serial: number): TListItem | undefined => this.getState().list.items.find(x => x.serial === serial);
    public readonly getById = (id: string): TListItem | undefined => this.getState().list.items.find(x => x.id === id);

    //---- EVENTS ↓ ------
    public readonly events = Object.freeze({
        onInitizlized: (handler: TEventListenerT<TState>): void => { this._events.onInitizlized.addListener(handler) },
        onBeginEdit: (handler: TEventListenerT<TListItem>): void => { this._events.onBeginEdit.addListener(handler) },
        onEditReset: (handler: TEventListenerT<{ cancelEdit: boolean }>): void => { this._events.onEditorReset.addListener(handler) },
        onAfterSubmit: (handler: TEventListenerT<TModel>): void => { this._events.onAfterSubmit.addListener(handler) },
        onListReset: (handler: TEventListener): void => { this._events.onListReset.addListener(handler) },
        removeAllEventListeners: () => this._events.removeAllListeners(),
    });

    public readonly editor: TCrudEditor<TModel, TListItem> = Object.freeze({
        getModel: () => this.getState().model,
        setModel: <TKey extends keyof TModel>(key: TKey, value: TModel[TKey]) => {
            let st = this.getState();
            if (st.model[key] != value) {
                this.dispatch(this.crudSlice.actions.setModel({ key: key, value: value }));
            }
        },
        isEditingMode: () => !Guid.isEmpty(this.getState().list.editingId),
        edit: (item: TListItem) => this.dispatch(this.crudSlice.actions.editItem(item)),
        reset: () => this.resetEditor({ cancelEdit: this.editor.isEditingMode() }),
        submit: () => {
            let st = this.getState();
            let model = { ...st.model };
            this.onBeforeSubmit(model, st.params);
            let submit: any = this.editor.isEditingMode()
                ? this.options.apis.update({ data: model, params: st.params })
                : this.options.apis.create({ data: model, params: st.params });
            this.dispatch(submit);
        }
    });

    public readonly list: TPagedListContext<TListItem> = Object.freeze({
        getLoading: () => this.getState().loading,
        getItems: () => this.getState().list.items,
        getTempQuery: () => this.getState().list.tempQuery,
        getItemsCount: () => this.getState().list.items.length,
        getTotalCount: () => this.getState().list.totalCount,
        getPageSize: () => this.getState().listLoadModel.pageSize ?? 10,
        getPageIndex: () => this.getState().listLoadModel.pageIndex ?? 0,
        getActiveSortField: () => this.getState().listLoadModel.sortBy,
        getActiveSortMode: () => this.getState().listLoadModel.sortOrder ?? 'none',
        getEditingItemId: () => this.getState().list.editingId,

        setTempQuery: (val: string) => { this.dispatch(this.crudSlice.actions.setListTempFilterQuery(val)) },
        acceptQuery: () => {
            let state = this.getState();
            if (state.list.tempQuery != state.listLoadModel.query)
                this.dispatch(this.slice.actions.acceptListTempFilterQuery(), { reloadList: true });
        },
        setPageIndex: (pageIndex: number) => {
            pageIndex = pageIndex < 0 ? 0 : pageIndex;
            let state = this.getState();
            if (pageIndex != state.listLoadModel.pageIndex)
                this.dispatch(this.crudSlice.actions.setListPageIndex(pageIndex), { reloadList: true });
        },
        setActiveSortField: (field: string) => { this.dispatch(this.crudSlice.actions.setListSortBy(field), { reloadList: true }) },
        setSort: (field: string, desc: boolean) => { this.dispatch(this.crudSlice.actions.setSort({ field, desc }), { reloadList: true }) },
        cancelEdit: () => { this.resetEditor({ cancelEdit: true }) },
        loadList: () => { this.loadList() },
        reset: () => { this.dispatch(this.slice.actions.resetList(), { reloadList: true }) },
        remove: (item: TListItem) => {
            MessageBox.modal.confirmDelete(this.describeItem(item)).then(accept => {
                if (accept) {
                    this.storeManager.dispatch(this.options.apis.delete({ data: item, params: this.getState().params }) as any);
                    //this.dispatch(this.options.apis.delete({ data: item, params: this.getState().params }) as any, { reloadList: true });
                }
            });
        },
        selectItem: (id: string): void => this.dispatch(this.crudSlice.actions.selectListItemById(id)),
        getSelectedId: () => this.getState().list.selectedItem?.id
    });
    //#endregion

    //#region PROTECTEDS
    protected readonly getState!: () => TState;
    protected readonly crudSlice!: Slice<TState, TReducers<TModel, TListItem, TParams>>;
    protected readonly slice!: Slice<TState, TReducers<TModel, TListItem, TParams> & TAdditionalReducers>;
    protected abstract describeItem(item: TListItem): string;
    protected dispatch(action: UnknownAction, options?: { reloadList?: boolean }): void {
        this.storeManager.dispatch(action);
        if (Boolean(options?.reloadList)) this.loadList();
    }

    protected onBeginEdit(item: TListItem): void { };
    protected onEditorReset(cancedlEdit: boolean): void { };
    protected onBeforeSubmit(item: TModel, params: TParams): void { }
    protected onAfterSubmit(item: TModel, params: TSubmitResultDto): void { }
    protected onModelChanging = <TKey extends keyof TModel>(state: Draft<TState>, item: TModel, actionPayload: { key: TKey, value: TModel[TKey] }): void => { }
    protected onModelChanged = <TKey extends keyof TModel>(state: Draft<TState>, item: TModel, actionPayload: { key: TKey, value: TModel[TKey] }): void => { }
    protected onListThunkFulFilled(state: Draft<TState>, action: PayloadAction<IListResult<TListItem>>): void { };
    protected initialized = (state: TState): void => this._events.onInitizlized.dispatch(state);
    //#endregion

    //#region PRIVATES
    private readonly initalState!: TState;
    private resetEditor = (options?: TResetModelOptions) => this.storeManager.dispatch(this.crudSlice.actions.resetModel(options ?? { cancelEdit: false }));
    private loadList = () => this.storeManager.dispatch(this.options.apis.list({ params: this.getState().params, data: this.getState().listLoadModel }) as any);
    private readonly _events = (() => {
        const _eventTarget: CrudServiceEventTarget = new CrudServiceEventTarget();
        const context = {
            onInitizlized: new EventCaseT<TState>(_eventTarget, `crud-service-${this.options.name}-on-initialized`),
            onBeginEdit: new EventCaseT<TListItem>(_eventTarget, `crud-service-${this.options.name}-on-begin-edit`),
            onEditorReset: new EventCaseT<{ cancelEdit: boolean }>(_eventTarget, `crud-service-${this.options.name}-on-editor-reset`),
            onAfterSubmit: new EventCaseT<TModel>(_eventTarget, `crud-service-${this.options.name}-on-item-submited`),
            onListReset: new EventCase(_eventTarget, `crud-service-${this.options.name}-on-list-reset`),
        };

        return Object.freeze({
            ...context,
            action: {
                beginEdit: (item: TListItem): void => {
                    context.onBeginEdit.dispatch(item);
                    this.onBeginEdit(item);
                },
                resetEditor: (cancelEdit: boolean) => {
                    context.onEditorReset.dispatch({ cancelEdit: cancelEdit });
                    this.onEditorReset(cancelEdit);
                },
                afterSubmit: (item: TModel, params: TSubmitResultDto): void => {
                    context.onAfterSubmit.dispatch(item);
                    this.onAfterSubmit(item, params);
                }
            },
            removeAllListeners: () => {
                let _context: any = context;
                Object.keys(context)
                    .forEach(key => {
                        (_context[key].removeAllListeners instanceof Function) && _context[key].removeAllListeners();
                    });
            }
        });
    })();
    //#endregion
}

class CrudServiceEventTarget extends EventTarget { };