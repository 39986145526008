import React, { useMemo } from 'react'
import './style.scss'

export type TSidebarItem = {
    id: string,
    title: string,
    actionLink?: string | (() => void),
    icon?: string,
    expanded?: boolean,
    subItems?: TSidebarItem[]
}

export type TSidebarProps = {
    id: string,
    width?: string,
    title: string,
    titleLink?: string,
    icon?: string,
    items: TSidebarItem[]
}

export function Sidebar(props: TSidebarProps) {
    const rootLink = useMemo(() => String.isEmpty(props.titleLink) ? '#/' : props.titleLink, [props.titleLink])
        , isCategory = (item: TSidebarItem): boolean => isArray(item.subItems)
        , style1: React.CSSProperties = {
            backgroundImage: 'url(images/bg_1.jpg)'
        }
        , style2: React.CSSProperties = {
            backgroundImage: 'url(images/logo.jpg)'
        };

    return <div id={props.id} className="sdiebar flex-shrink-0" style={{ width: props.width ?? '200px' }}>
        <a href={rootLink} className="menu-title d-flex text-decoration-none">{props.title}</a>
        {/* <div className="img bg-wrap text-center py-4" style={style1}>
            <div className="user-logo">
                <div className="img" style={style2}></div>
                <h3>Catriona Henderson</h3>
            </div>
        </div> */}

        <ul className="list-unstyled main-menu ps-0 mt-3">
            {props.items.map((e, i) => !isCategory(e) ? <ActionLink key={`sidebar-item-${i}`} item={e} /> : <SidbarCategory key={`sidbar-category-${i + 1}`} item={e} />)}
        </ul>
    </div>
}

const SidbarCategory = function (props: { item: TSidebarItem }) {
    const toggle = function (e: React.MouseEvent<HTMLButtonElement>) {
        let li = (e.target as HTMLElement).closest('li')!;
        let $: any = (window as any).$;
        $(li.querySelector('div.collapse')).slideToggle();
    }

    let item = props.item;
    return <li className="mb-1">
        <button onClick={toggle} className="btn btn-toggle align-items-center collapsed" data-bs-toggle="collapse" data-bs-target={item.id} aria-expanded={item.expanded ? 'true' : 'false'}>
            {!String.isEmpty(item.icon) && <i className={item.icon}></i>} {item.title}
        </button>
        <div className={`collapse ${item.expanded ? 'show' : ''}`.trim()} id={item.id}>
            {item.subItems && item.subItems.length > 0 && <SidbarCategoryItem items={item.subItems} />}
        </div>
    </li>
}
    , getLink = (item: TSidebarItem): string | undefined => {
        if (isFunc(item.actionLink)) return undefined;
        let link: any = item.actionLink;
        return !String.isEmpty(link) ? link : undefined;
    }
    , getAction = (item: TSidebarItem): ((e: React.MouseEvent) => void) | undefined => {
        if (!isFunc(item.actionLink)) return undefined;
        return ((fn: () => void, e: React.MouseEvent) => {
            e.preventDefault();
            fn();
        }).bind(item, item.actionLink as (() => void));
    }
    , ActionLink = function (props: { item: TSidebarItem }) {
        const item = props.item;
        return <li><a id={item.id} onClick={getAction(item)} href={getLink(item)} >{!String.isEmpty(item.icon) && <i className={item.icon}></i>} {item.title}</a></li>
    }
    , SidbarCategoryItem = function (props: { items: TSidebarItem[] }) {
        return <ul className="btn-toggle-nav list-unstyled fw-normal pb-1 small">
            {props.items.map((e, i) => <li key={`sidbar-category-item-${i + 1}`}><a onClick={getAction(e)} href={getLink(e)}>{!String.isEmpty(e.icon) && <i className={e.icon}></i>}{e.title}</a></li>)}
        </ul>
    }