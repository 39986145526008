import { EventService } from "@app/core";
import { AuthService } from "@app/services";
import { useEffect } from "react";
import { useSelector } from "react-redux";
import { Navigate, Outlet, useLocation } from "react-router"

export const AppRoot = () => {
    const location = useLocation()
        , userIsAuthenticated = useSelector(AuthService.instance.session.getUserIsAuthenticated)
        , shouldRedirectToMain = () => userIsAuthenticated && location.pathname.startsWith('/auth')
        , shouldRedirectToLogin = () => !userIsAuthenticated && !location.pathname.startsWith('/auth')
        , onUserSignedIn = () => { window.location.pathname = '/'; }


    useEffect(() => {
        EventService.instance.userSignedIn.addListener(onUserSignedIn);
        return () => { EventService.instance.userSignedIn.removeListener(onUserSignedIn); }
    }, []);

    return <>
        {shouldRedirectToMain() ? <Navigate to='/' /> : shouldRedirectToLogin() ? <Navigate to='/auth' /> : <Outlet />}
    </>
}