import {
    EnhancedStore,
    Action,
    configureStore,
    CreateSliceOptions,
    Slice,
    SliceCaseReducers,
    combineReducers,
    createSlice,
    UnknownAction,
    AsyncThunkAction,
} from "@reduxjs/toolkit";

export type TSharedState = { loading: boolean, test: string };
export type TSharedReducer = SliceCaseReducers<TSharedState>;

export interface IStoreManager {
    get store(): EnhancedStore;
    dispatch: (thunkAction: UnknownAction) => void,
    createSlice: <TState, TReducers extends SliceCaseReducers<TState>>(options: CreateSliceOptions<TState, TReducers>, dropIfExists?: boolean) => [Slice<TState, TReducers>, () => TState],
    removeSlice: (name: string) => void,
    setLoading: (loading: boolean) => void
}

export const initStore = function (): IStoreManager {
    const myThunkMiddleware = (store: any) => (next: any) => (action: any) => {

        if (action.type === 'SOME_ACTION_TYPE') {
            const shared: TSharedState = store.getState().shared;
            shared.loading = true;
            // Perform logic using dataFromOtherSlice
        }
        return next(action);
    };

    const sliceCollection: { [key: string]: any } = {},
        registeredReducers: { [key: string]: any } = {},
        store = configureStore({
            // preloadedState: {},
            reducer: {
                default: (state = {}) => state
            },
            //  middleware: (getDefaultMiddleware) => getDefaultMiddleware().concat(myThunkMiddleware)
        }),


        context: any = {
            dispatch: (thunkAction: Action) => store.dispatch(thunkAction),
            createSlice: <TState, TReducers extends SliceCaseReducers<TState>>(
                options: CreateSliceOptions<TState, TReducers>, 
                dropIfExists?: boolean
            ): [Slice<TState, TReducers>, () => TState] => {
                if (sliceCollection[options.name]) {
                    if (dropIfExists)
                        context.removeSlice(options.name);
                    else
                        return sliceCollection[options.name];
                }
                //if (registeredReducers[options.name])
                // throw new Error(`A slice is already has registered with the name "${options.name}".`, { cause: 'DuplicateName' });



                let slice = createSlice(options);

                registeredReducers[slice.name] = slice.reducer;

                let updatedRootReducer: any = combineReducers(registeredReducers);

                store.replaceReducer(updatedRootReducer);

                var state = store.getState();

                sliceCollection[options.name] = [
                    slice,
                    ((sliceName: string) => (store.getState() as any)[sliceName]).bind({}, options.name),
                ];

                return sliceCollection[options.name];
            },
            removeSlice: (name: string) => {
                if (!registeredReducers[name]) return;

                delete registeredReducers[name];
                delete sliceCollection[name];

                let updatedRootReducer: any = combineReducers(registeredReducers);

                store.replaceReducer(updatedRootReducer);

                console.warn(`The store slice "${name}" has been removed.`);
            },
        };


    return Object.defineProperties<IStoreManager>(context, {
        store: { get: () => store }
    });
}