import { Icons, helpers } from "@app/core";
import { ClientService } from "@app/services";
import { clientTypes } from "@app/types";
import { ActionLink, CardView, Checkbox, Offcanvas, PillBadge, TCardItem, TForm, TSortOption, TextArea, TextInput, offcanvasApi } from "@dariosoft/components";
import { useEffect } from "react";
import { useSelector } from "react-redux";


export function ClientList() {
    const service = ClientService.instance
        , editorPaneId = 'editor-pane'
        , securityPaneId = 'security-pane'
        , model = useSelector(service.editor.getModel)
        , credentials = useSelector(service.credentials.getModel)
        , loading = useSelector(service.getLoading)
        , addons = <>
            <ActionLink onClick={service.list.loadList} title={I18n.translates.Refresh} css="btn btn-sm btn-outline-dark" icon={Icons.refresh} />&nbsp;
            <ActionLink onClick={() => { offcanvasApi.show(editorPaneId) }} title={I18n.translates.Add} css="btn btn-sm btn-outline-dark" icon={Icons.add} />
        </>
        , onEditorShown = () => { tryFocus('#txtName'); }
        , onSecuritPaneShown = () => { tryFocus('#txtAdminUserName'); }
        , sortOptions: Array<TSortOption> = [
            { field: 'name', label: 'Sort by Name' },
            { field: 'createdAt', label: 'Sort by Creation Time' },
            { field: 'enabled', label: 'Sort by Enable' }
        ]
        , carViewFormat = (e: clientTypes.TClientModel): TCardItem<clientTypes.TClientModel> => ({
            data: e,
            id: e.id,
            header: e.name,
            body: {
                description: e.description
            },
            list: [
                { label: I18n.translates.CreatedAt, value: e.createdAt },
                { label: I18n.translates.Enabled, value: <PillBadge value={e.enabled} mode={e.enabled ? 'success' : 'danger'} /> },
            ],
            actions: [
                { icon: Icons.edit, css: 'text-body', text: I18n.translates.Edit, action: e => service.editor.edit(e.data), visible: e => !e.data.isSystem },
                { icon: `${Icons.trash} text-danger`, css: 'text-body', text: I18n.translates.Delete, action: e => service.list.remove(e.data), visible: e => !e.data.isSystem },
                { icon: `${Icons.credential} text-primary`, css: 'text-body', text: I18n.translates.Credentials, action: e => service.credentials.load(e.data, () => offcanvasApi.show(securityPaneId)), visible: e => !e.data.isSystem },
                { icon: Icons.template, css: 'text-body', text: I18n.translates.Templates, link: e => `#/clients/${e.id}/templates` },
            ]
        })
        , securityOtherButtons = <>
            <button onClick={() => service.credentials.setModel('adminPassword', helpers.securityHelper.generateRandomPassword({ length: 10, includeSymbols: false }))} type="button" className="btn btn-sm btn-primary">{I18n.translates.GeneratePassword}</button>
        </>;

    useEffect(() => {
        service.list.loadList();
        service.events.onBeginEdit(e => { offcanvasApi.show(editorPaneId) });
        service.events.onEditReset(e => { offcanvasApi.hide(editorPaneId) });
        service.events.onAfterSubmit(e => { offcanvasApi.hide(editorPaneId) });

    }, []);

    return <div className='vw vw-client-list'>
        <Offcanvas id={editorPaneId} header={I18n.translates.Editor} onShown={onEditorShown}>
            <TForm id="client-editor" onSubmit={service.editor.submit} onReset={service.editor.reset} lastTabIndex={4} loading={loading} resetButtonVisible={true}>
                <TextInput id="txtName" label={I18n.translates.Name} value={model.name} onChanged={val => service.editor.setModel('name', val ?? '')} maxLength={64} tabIndex={1} autoFocus />
                <TextInput id="txtAdminEmail" label={I18n.translates.AdminEmail} value={model.adminEmail} onChanged={val => service.editor.setModel('adminEmail', val ?? '')} type="email" maxLength={255} tabIndex={2} />
                <TextArea id="txtDescription" label={I18n.translates.Description} value={model.description} onChanged={val => service.editor.setModel('description', val)} maxLength={255} tabIndex={3} optional />
                <Checkbox id="chkEnabled" label={I18n.translates.Active} value={model.enabled} onChanged={val => service.editor.setModel('enabled', val)} tabIndex={4} />
            </TForm>
        </Offcanvas>
        <Offcanvas id={securityPaneId} header={I18n.translates.Credentials} onShown={onSecuritPaneShown}>
            <>
                <TForm id="client-editor" lastTabIndex={2} loading={loading} onSubmit={service.credentials.updateAdminPassword} submitText={I18n.translates.Update} otherButtons={securityOtherButtons}>
                    <TextInput id="txtAdminUserName" label={I18n.translates.AdminUsername} value={credentials.adminUsername} onChanged={val => service.credentials.setModel('adminUsername', val ?? '')} maxLength={64} tabIndex={1} autoFocus />
                    <TextInput id="txtAdminPassword" label={I18n.translates.AdminPassword} description="At least 8 characters of digits, lower-case and upper-case letters" value={credentials.adminPassword} onChanged={val => service.credentials.setModel('adminPassword', val ?? '')} maxLength={64} tabIndex={2} />
                </TForm>
                <hr />
                <TextInput id="txtApiKey" label={I18n.translates.ApiKey} value={credentials.apiKey} onChanged={val => { }} disabled optional />
                <TextArea id="txtSecretKeyKey" label={I18n.translates.SecretKey} value={credentials.secretKey} onChanged={val => { }} disabled css="mt-3" optional />
                <div><button className="btn btn-sm btn-primary" onClick={service.credentials.generateApiKey} tabIndex={7}>{I18n.translates.GenerateSecretKey}</button></div>
            </>
        </Offcanvas>
        <CardView
            rowCols={{ md: 3, xxl: 4 }}
            context={service.list}
            format={carViewFormat}
            searchBoxVisible={true}
            sortOptions={sortOptions}
            extensions={addons}
        />
    </div>
}