import { templateTypes, clientTypes } from '@app/types';
import { CrudService, TAction, TActionT, TCrudState } from '@dariosoft/tools';
import { StoreManager } from '../store-manager';
import * as api from '@app/api';
import { TSelectItem } from '@dariosoft/components';
import { Draft, PayloadAction } from '@reduxjs/toolkit';

type TParams = {
    clientId: string
};

type TState = TCrudState<templateTypes.TTemplateInfo, templateTypes.TTemplateModel, TParams> & {
    initialized: boolean,
    autoGeneratePath: boolean,
    clientInfo: clientTypes.TClientModel | undefined | null
}

type TReducers = {
    closeService: TAction<TState>,
    setAutoGeneratePath: TActionT<TState, boolean>,
}

export class TemplateService extends CrudService<templateTypes.TTemplateInfo, templateTypes.TTemplateModel, TParams, TState, TReducers> {
    private constructor() {
        super(StoreManager, {
            name: 'TemplateService',
            getInitalState: () => {
                return {
                    loading: false,
                    clientInfo: null,
                    initialized: false,
                    autoGeneratePath: true,
                    params: {
                        clientId: '',
                    },
                    model: templateTypes.createDefaultTemplateInfo(),
                    context: { templateId: '', dataModel: '{}', subject: '', body: '<div></div>' },
                    list: {
                        items: [],
                        totalCount: 0,
                        editingId: undefined,
                        selectedItem: undefined,
                        tempQuery: ''
                    },
                    listLoadModel: {
                        pageIndex: 0,
                        pageSize: 15,
                        params: {},
                        query: '',
                        sortBy: 'name',
                        sortOrder: 'asc'
                    }
                }
            },
            apis: {
                create: api.TemplateApi.instance.create.thunk,
                update: api.TemplateApi.instance.update.thunk,
                delete: api.TemplateApi.instance.delete.thunk,
                list: api.TemplateApi.instance.list.thunk,
            },
            additionalReducers: {
                closeService: (state: TState): void => {
                    state.list.items = [];
                    state.list.editingId = undefined;
                    state.list.selectedItem = undefined;
                    state.list.tempQuery = '';
                    state.listLoadModel.pageIndex = 0;
                    state.listLoadModel.query = '';
                    state.model.description = '';
                    state.model.name = '';
                    state.model.lang = 'en-US';
                    state.model.path = '/en';
                    state.model.ownerId = '';
                    state.model.id = '';
                    state.model.enabled = false;
                    state.params.clientId = '';
                    state.loading = false;
                },
                setAutoGeneratePath: (state: TState, action: PayloadAction<boolean>): void => {
                    state.autoGeneratePath = action.payload;
                    if (state.autoGeneratePath)
                        state.model.path = this.generatePath({ lang: state.model.lang, name: state.model.name, version: state.model.version });
                }
            },
            actionReducerMapBuilder: (builder) => {
                builder.addCase(api.ClientApi.instance.get.thunk.pending, state => {
                    state.params.clientId = '';
                    state.clientInfo = null;
                    state.loading = true;
                })
                    .addCase(api.ClientApi.instance.get.thunk.rejected, state => { state.loading = false })
                    .addCase(api.ClientApi.instance.get.thunk.fulfilled, (state, action) => {
                        state.loading = false;
                        try {
                            if (action.payload.isSuccessful && isPlainObject(action.payload.data)) {
                                state.clientInfo = action.payload.data;
                                state.params.clientId = state.model.ownerId = action.payload.data!.id;
                                setTimeout(this.list.loadList, 30);
                            }
                        }
                        finally {
                            state.initialized = true;
                            setTimeout(this.initialized.bind(this, { ...state }), 20);
                        }
                    })
                    ;
            }
        });

    }

    //#region PUBLICS
    public languages: TSelectItem<string>[] = ['en-US', 'fa-IR', 'tr-TR', 'ar-SA', 'de-DE', 'fr-FR', 'es-ES', 'zh-cn'].map(x => ({ text: x, value: x }));
    public getClientInfo = (): clientTypes.TClientInfo | null | undefined => this.getState().clientInfo;
    public getInitizlied = (): boolean => this.getState().initialized;

    public getAutoGeneratePath = (): boolean => this.getState().autoGeneratePath;
    public setAutoGeneratePath = (value: boolean): void => StoreManager.dispatch(this.slice.actions.setAutoGeneratePath(value));

    public reset = (clientId: string): void => api.ClientApi.instance.get.submit(clientId);
    public close = (): void => StoreManager.dispatch(this.slice.actions.closeService());


    public static get instance(): TemplateService {
        if (TemplateService._instance == null) TemplateService._instance = new TemplateService();
        return TemplateService._instance!;
    }

    //#endregion

    //#region PROTECTEDS
    protected describeItem = (item: templateTypes.TTemplateModel): string => item.name;

    protected onModelChanging = (state: TState, model: templateTypes.TTemplateInfo, payload: { key: keyof templateTypes.TTemplateInfo, value: any }): void => {
        if (payload.key == 'name' && state.autoGeneratePath)
            state.model.path = this.generatePath({ lang: state.model.lang, name: payload.value, version: model.version });

        if (payload.key == 'lang' && state.autoGeneratePath)
            state.model.path = this.generatePath({ lang: payload.value, name: state.model.name, version: model.version });

        if (payload.key == 'version') {
            payload.value = payload.value.replaceAll(' ', '');
            state.model.path = this.generatePath({ lang: state.model.lang, name: state.model.name, version: payload.value });
        }
    }


    //#endregion

    //#region PRIVATES
    private static _instance: TemplateService | null = null;
    private generatePath = (info: { lang: string, name: string, version: string }) => {
        return '/' + [info.lang.substring(0, 2), info.version ?? '', info.name.replaceAll(/[^0-9a-z]/gi, '-')]
        .filter(e => !String.isEmpty(e))
        .join('/').toLowerCase();
    }
    //#endregion
}