export { };

export type TError = string | Error | IResultStatus | []; //Array<string> | Array<IResultStatus>;

declare global {
    type IResultStatus = { text: string, code?: string };

    interface IResult<T = any> {
        isSuccessful: boolean;
        statusCode: number;
        errors?: Array<IResultStatus>;
        warnings?: Array<IResultStatus>;
        data?: T;
        metaData?: any;
    }

    interface IListResult<T = any> extends IResult<T[]> {
        pageIndex: number;
        pagesCount: number;
        pageSize: number;
        totalCount: number;
    }

    const Result: {
        success: (statusCode?: number) => IResult,
        fail: (error: TError, statusCode?: number) => IResult,
        successT: <T = any>(data?: T, statusCode?: number) => IResult<T>,
        failT: <T = any>(error: TError, statusCode?: number) => IResult<T>,
        listSuccess: <T = any>(data?: T[], pageIndex?: number, pageSize?: number, pagesCount?: number, totalCount?: number, statusCode?: number) => IListResult<T>
        listFail: <T = any>(error: TError, statusCode?: number, pageIndex?: number, pageSize?: number) => IListResult<T>
    }
}

const win: any = window;

win.$parseError = (input: TError): Array<IResultStatus> => {
    if (typeof (input) === 'string')
        return [{ text: input }];

    if (input instanceof Error)
        return [{ text: input.message, code: input.name }];

    if (isPlainObject(input)) {
        let e: any = input;
        return [{ text: e.text, code: e.code }];
    }

    if (input instanceof Array) {
        return input
            .filter(e => Boolean(e))
            .map((e: any) => {
                return typeof (e) === 'string'
                    ? { text: e }
                    : e instanceof Error
                        ? { text: e, code: e.name }
                        : { text: e.text, code: e.code };
            });
    }

    return [];
}

win.Result = {
    success: (statusCode: number = 0): IResult => ({ isSuccessful: true, statusCode: statusCode < 0 ? 0 : statusCode } as IResult),
    fail: (error: TError, statusCode: number = 0): IResult => ({ isSuccessful: false, statusCode: statusCode < 0 ? 0 : statusCode, errors: win.$parseError(error) } as IResult),
    successT: <T = any>(data?: T, statusCode: number = 0): IResult<T> => ({ isSuccessful: true, data: data, statusCode: statusCode < 0 ? 0 : statusCode }),
    failT: <T = any>(error: TError, statusCode: number = 0): IResult<T> => ({ isSuccessful: false, statusCode: statusCode < 0 ? 0 : statusCode, errors: win.$parseError(error) }),
    listSuccess: <T = any>(data?: T[], pageIndex: number = 0, pageSize: number = 10, pagesCount: number = 1, totalCount: number = 0, statusCode: number = 0): IListResult<T> => ({
        isSuccessful: true,
        data: data,
        statusCode: statusCode < 0 ? 0 : statusCode,
        pageIndex: pageIndex < 0 ? 0 : pageIndex,
        pageSize: pageSize < 1 ? 1 : pageSize,
        pagesCount: pagesCount < 1 ? 1 : pagesCount,
        totalCount: totalCount < 0 ? 0 : totalCount
    }),
    listFail: <T = any>(error: TError, statusCode: number = 0, pageIndex: number = 0, pageSize: number = 10): IListResult<T> => ({
        isSuccessful: false,
        errors: win.$parseError(error),
        statusCode: statusCode < 0 ? 0 : statusCode,
        pageIndex: pageIndex < 0 ? 0 : pageIndex,
        pageSize: pageSize < 1 ? 1 : pageSize,
        pagesCount: 0,
        totalCount: 0
    })
}

