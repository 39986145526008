export { };

const longMonthNames = Object.freeze(["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"])
    , shortMonthNames = Object.freeze(["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"]);

declare global {
    interface DateConstructor {
        isValidPersinDate(input: string): boolean;
        nowDate(): Date;
    }

    interface Date {
        addSeconds(x: number): Date;
        addMinutes(x: number): Date;
        addHours(x: number): Date;
        addDays(x: number): Date;
        addMonths(x: number): Date;
        addYears(x: number): Date;
        format(format: string): string;
        getMonthName(shortName?: 'short' | 'long'): string;
        toUtc(): string;
        utcDateOnly(): { year: number, month: number, day: number };
    }


}

//#region Date - Constructor
Date.nowDate = () => new Date();

Date.isValidPersinDate = (input: string) => {
    return [
        /^1[2-9]\d{2}\/0[1-6]\/(0[1-9]|[1-2][0-9]|3[0-1])$/,
        /^1[2-9]\d{2}\/(0[7-9]|1[0-2])\/(0[1-9]|[1-2][0-9]|30)$/
    ].some(regex => regex.test(input));
}
//#endregion

//#region Date - Extensions


Date.prototype.addSeconds = function (x: number) {
    var date = new Date(this.valueOf());
    date.setSeconds(this.getSeconds() + x);
    return date;
}

Date.prototype.addMinutes = function (x: number) {
    var date = new Date(this.valueOf());
    date.setMinutes(this.getMinutes() + x);
    return date;
}

Date.prototype.addHours = function (x: number) {
    var date = new Date(this.valueOf());
    date.setHours(this.getHours() + x);
    return date;
}

Date.prototype.addDays = function (x: number) {
    var date = new Date(this.valueOf());
    date.setDate(this.getDate() + x);
    return date;
}

Date.prototype.addMonths = function (x: number) {
    var date = new Date(this.valueOf());
    date.setMonth(this.getMonth() + x);
    return date;
}

Date.prototype.addYears = function (x: number) {
    var date = new Date(this.valueOf());
    date.setFullYear(this.getFullYear() + x);
    return date;
}

Date.prototype.format = function (format: string): string {
    if (String.isEmpty(format)) return '';

    let year = this.getFullYear().toString()
        , month = this.getMonth() + 1
        , day = this.getDate()
        , h = this.getHours()
        , m = this.getMinutes()
        , s = this.getSeconds()
        , ms = this.getMilliseconds();

    format = format
        .replaceAll('yyyy', year)
        .replaceAll('yy', year.substring(2))
        .replaceAll('dd', day.toString().padStart(2, '0'))
        .replaceAll('d', day.toString())
        .replaceAll('hh', h.toString().padStart(2, '0'))
        .replaceAll('h', h.toString())
        .replaceAll('mm', m.toString().padStart(2, '0'))
        .replaceAll('m', m.toString())
        .replaceAll('ss', s.toString().padStart(2, '0'))
        .replaceAll('s', s.toString())
        .replaceAll('fff', ms.toString().padStart(3, '0'))
        .replaceAll('ff', ms.toString().padStart(2, '0'))
        .replaceAll('f', ms.toString());

    /*NOTE: Replacement of the month part should be after the replacing of the minute part. 
       Because if the month part replaced before the minute part and the if the month name contains the "m" character(e.g. "September"), 
       the "m" character of the month name might be replaced by the minute value.
*/

    if (format.search('MMMM') >= 0)
        format = format.replaceAll('MMMM', longMonthNames[month - 1])
    else if (format.search('MMM') >= 0)
        format = format.replaceAll('MMM', shortMonthNames[month - 1])
    else if (format.search('MM') >= 0)
        format = format.replaceAll('MM', month.toString().padStart(2, '0'))
    else
        format = format.replaceAll('M', `${month.toString()}`)
    return format;
}


Date.prototype.getMonthName = function (shortName?: 'short' | 'long') {
    return shortName === 'short'
        ? shortMonthNames[this.getMonth()]
        : longMonthNames[this.getMonth()];
}

Date.prototype.toUtc = function () {
    return `${this.getUTCFullYear()}-${this.getUTCMonth() + 1}-${this.getUTCDate()} ${this.getUTCHours()}:${this.getUTCMinutes()}:${this.getUTCSeconds()}.${this.getUTCMilliseconds()}`;
}

Date.prototype.utcDateOnly = function () {
    return {
        year: this.getUTCFullYear(),
        month: this.getUTCMonth() + 1,
        day: this.getUTCDate()
    };
}


//#endregion