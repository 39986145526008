export { };

type TKey = string | number | symbol;
type TPlainObject = { [key: TKey]: any };

declare global {
    interface ObjectConstructor {
        deepAssign(dest: TPlainObject, source: TPlainObject): any;
    }
}

let win: any = window;
win.$deepAssign = Object.deepAssign = function (dest, source) {
    const fill = (target: TPlainObject, key: TKey, value: any) => {
        let t = typeof (value);

        if (['string', 'number', 'boolean', 'function'].some(x => x == t) || value instanceof Date)
            target[key] = value;
        else if (value instanceof Array) {
            target[key] = new Array(value.length);
            value.forEach((item, i) => fill(target[key], i, item));
        }
        else if (isPlainObject(value)) {
            target[key] = {};
            Object.keys(value).forEach(k => fill(target[key], k, value[k]));
        }
        else{
            target[key] = value;
        }
    }

    Object.keys(source).forEach(key => fill(dest, key, source[key]));

    return dest;
}

