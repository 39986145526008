import { FieldView, TForm, TextInput } from '@dariosoft/components'
import './style.scss'
import { AuthService, UserProfileService } from '@app/services'
import { useSelector } from 'react-redux';
import { useEffect, useMemo } from 'react';

export function UserProfile() {
    const settingService = UserProfileService.instance,
        updateEmailModel = useSelector(settingService.updateEmail.getModel),
        settingServiceLoading = useSelector(settingService.getLoading),
        authService = AuthService.instance,
        changePasswordModel = useSelector(authService.changePassword.getModel),
        authServiceLoading = useSelector(authService.getLoading),
        validateConfirmPassword = useSelector(authService.changePassword.validateConfirmPassword),
        session = useMemo(authService.session.getInfo, [])
        ;

    useEffect(() => {
        settingService.reset();
        authService.reset();
    }, []);
    return <div className="vw vw-settings">
        <fieldset className='mb-3'>
            <legend>{I18n.translates.Information}</legend>
            <FieldView label={I18n.translates.UserName} value={session.username} css='mb-2' />
            <TForm id='formUpdateEmailAddress' loading={settingServiceLoading} onSubmit={settingService.updateEmail.submit} onReset={settingService.updateEmail.reset} resetButtonVisible submitText={I18n.translates.Update} resetText={I18n.translates.Reset} lastTabIndex={101}>
                <TextInput id='txtEmail' type='email' label={I18n.translates.CurrentEmailAddress} value={updateEmailModel.email} onChanged={val => settingService.updateEmail.setModel('email', val ?? '')} tabIndex={101} autoFocus />
            </TForm>
        </fieldset>
        <fieldset>
            <legend>{I18n.translates.ChangePassword}</legend>
            <TForm id='formUpdatePassword' loading={authServiceLoading} onSubmit={authService.changePassword.submit} submitText={I18n.translates.Submit} lastTabIndex={203}>
                <TextInput id='txtCurrentPassword' label={I18n.translates.CurrentPassword} value={changePasswordModel.currentPassword} onChanged={val => authService.changePassword.setModel('currentPassword', val ?? '')} type='password' tabIndex={201} />
                <TextInput id='txtNewPassword' label={I18n.translates.NewPassword} value={changePasswordModel.newPassword} onChanged={val => authService.changePassword.setModel('newPassword', val ?? '')} type='password' tabIndex={202} />
                <TextInput id='txtConfirmPassword' errors={validateConfirmPassword} label={I18n.translates.ConfirmNewPassword} value={changePasswordModel.confirmPassword} onChanged={val => authService.changePassword.setModel('confirmPassword', val ?? '')} type='password' tabIndex={203} />
            </TForm>
        </fieldset>
    </div>
}