import { Outlet, useMatch } from "react-router-dom";

export type TSubLayoutProps = {
    layoutRouteMatch: string;
    css?: string;
    children?: React.ReactNode
};

export function SubLayout(props: TSubLayoutProps) {
    const match = useMatch(props.layoutRouteMatch);

    return <div className={`vw vw-sub-layout ${props.css ?? ''}`.trim()}>
        {Boolean(match) ? <>{props.children}</> : <Outlet />}
    </div>
}