import ReactCodeMirror, { Extension } from "@uiw/react-codemirror"

export type TCodeEditorProps = {
    id: string,
    label?: string,
    description?: string,
    css?: string,
    tabIndex?: number,
    autoFocus?: boolean,
    disabled?: boolean,
    readonly?: boolean,
    optional?: boolean,
    value?: string | undefined | null,
    onChanged?: (value: string, e?: React.ChangeEvent) => void,
    theme: Extension,
    syntax: Extension
}

export function CodeEditor(props: TCodeEditorProps) {
    return <div className={`mb-3 form-input-element multi-line-text-input ${props.css ?? ''}`.trim()}>
        {!String.isEmpty(props.label) && <label htmlFor={props.id} className="form-label" aria-required={!props.optional}>{props.label}</label>}
        {!String.isEmpty(props.description) && <p>{props.description}</p>}
        <ReactCodeMirror
            id={props.id}
            value={props.value ?? ''}
            onChange={(val, e) => isFunc(props.onChanged) && props.onChanged!(val)}
            autoFocus={props.autoFocus}
            tabIndex={props.tabIndex}
            readOnly={props.readonly}
            aria-disabled={props.disabled}
            theme={props.theme}
            extensions={[props.syntax]}
            aria-required={props.readonly}
        />
    </div>
}