import { TLoginResult } from "./types";

const tokenKey: string = 'user-t', infoKey = 'user-info';
const getEmptyInfo = (): TLoginResult => ({ token: '', displayName: '', username: '', email: '' });

export class SessionManager {
     private constructor() { }

    //#region PUBLICS
    public static get instance(): SessionManager {
        if (SessionManager._instance == null) SessionManager._instance = new SessionManager();
        return SessionManager._instance!;
    }

    public getToken = () => localStorage.getItem(tokenKey) ?? '';
    public clear(): void {
        localStorage.removeItem(tokenKey);
        localStorage.removeItem(infoKey);
    }
    public getInfo(): TLoginResult {
        try {
            let json = localStorage.getItem(infoKey) ?? '{}';
            let res = JSON.parse(json);
            if (!isPlainObject(res)) res = {};

            if (this.ValidateStamp(res.token)) {
                this.renew(res);
                res.token = localStorage.getItem(tokenKey);
                return res;
            }
            else
                return getEmptyInfo();
        }
        catch {
            return getEmptyInfo();
        }
    }
    public persits(result: TLoginResult): void {
        localStorage.setItem(tokenKey, result.token);
        result.token = this.newStamp().toString();
        localStorage.setItem(infoKey, JSON.stringify(result));
    }
    public get hasInfo(): boolean {
        let info = this.getInfo();
        return isPlainObject(info) && !String.isEmpty(info.token) && !String.isEmpty(info.username);
    }
    //#endregion

    //#region PRIVATES
    private static _instance: SessionManager | null = null;
    private ValidateStamp = (stamp: string): boolean => {
        let n: number = +stamp;
        if (isNaN(n) || n == Infinity) return false;
        let w = new Date().getTime();
        return n >= w;
    }
    private renew = (result: TLoginResult): void => {
        result.token = this.newStamp().toString();
        localStorage.setItem(infoKey, JSON.stringify(result));
    }
    private newStamp = (): number => new Date().addHours(2).getTime();
    //#endregion
}