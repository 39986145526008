import React from "react";

export type TActionLinkProps = {
    onClick: (e: React.MouseEvent, params?: any) => void,
    params?: any,
    title?: string,
    text?: string,
    css?: string,
    icon?: string
};

export function ActionLink(props: TActionLinkProps) {
    return <a onClick={e => { e.preventDefault(); props.onClick instanceof Function && props.onClick(e, props.params); }} title={props.title} className={props.css} href="#">
        {!String.isEmpty(props.icon) && <i className={props.icon}></i>}
        {props.text}
    </a>
}