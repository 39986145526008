export type THeaders = Record<string, string>;
export type TRequest = { url: string, headers?: THeaders };
export type TBodyRequest<T> = TRequest & { data?: T };
export type THttpCall = (req: TRequest) => Promise<Response>;

export class DarioHttpClient {
    public get = (request: TRequest): Promise<Response> => {
        return fetch(request.url, { method: 'GET', headers: isPlainObject(request.headers) ? request.headers : {} });
    };

    public delete = (request: TRequest): Promise<Response> => {
        return fetch(request.url, { method: 'DELETE', headers: isPlainObject(request.headers) ? request.headers : {} });
    };

    public post = (request: TRequest): Promise<Response> => {
        return fetch(request.url, { method: 'POST', headers: isPlainObject(request.headers) ? request.headers : {} });
    };

    public put = (request: TRequest): Promise<Response> => {
        return fetch(request.url, { method: 'PUT', headers: isPlainObject(request.headers) ? request.headers : {} });
    };

    public postJson = (request: TBodyRequest<any[] | TPlainObject>): Promise<Response> => {
        let headers = (isPlainObject(request.headers) ? request.headers : {})!;
        headers['content-type'] = 'application/json';
        let data = isPlainObject(request.data) || (request.data instanceof Array) ? request.data : {};
        return fetch(request.url, { method: 'POST', body: JSON.stringify(data), headers: headers });
    };

    public postFormUrlEncoded = (request: TBodyRequest<TPlainObject>): Promise<Response> => {
        let headers = (isPlainObject(request.headers) ? request.headers : {})!;
        headers!['content-type'] = 'application/x-www-form-urlencoded';
        let data: any = isPlainObject(request.data) ? request.data : {};
        data = Object.keys(data).map(key => `${key}=${data[key]}`).join('&');
        return fetch(request.url, { method: 'POST', body: data, headers: headers });

    };

    public uploadFile = (request: TBodyRequest<Blob>): Promise<Response> => {
        return fetch(request.url, { method: 'POST', body: request.data, headers: isPlainObject(request.headers) ? request.headers : {} });
    };
}
