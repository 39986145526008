import './style.scss'
import { useMemo } from 'react'
import { TFieldValue } from '../common-types'

export type TFieldViewProps = {
    label: string,
    value: TFieldValue,
    css?: string
    labelCss?: string
    valueCss?: string
}

export const FieldView = function (props: TFieldViewProps) {
    const valIsPrimitive = useMemo(() => !isPlainObject(props.value), [props.value]),
        value = useMemo(() => {
            if (isDate(props.value)) return (props.value as Date).format('dd-MMM-yyyy hh:mm');
            if (isBoolean(props.value)) return props.value ? 'True' : 'False';
            return props.value?.toString() ?? '';
        }, [props.value]);

    return <div className={`field-view ${props.css ?? ''}`.trim()}>
        <label className={props.labelCss}>{props.label}:</label>
        {valIsPrimitive && <span className={props.valueCss}>{value}</span>}
        {!valIsPrimitive && <>{props.value}</>}

    </div>
}