import { StoreManager } from "@app/core"
import { Draft, PayloadAction, Slice } from "@reduxjs/toolkit"
import { MessageBox } from "../message-box"
import { opneFileBrowseDialog } from "./open-file-dialog"
import { IFileUploadApi, ITempFileApi } from './file-uploader-apis'


const win: any = window;
if (!isPlainObject(win.$$dariosoft_fileCollections))
    win.$$dariosoft_fileCollections = {};

const fileCollections: TPlainObject = win.$$dariosoft_fileCollections;


export type TUploaderOptions = {
    id: string,
    sizeLimit: null | TFileSize,
    accept: undefined | null | string
}

export type TUploadStatus = 'None' | 'Pending' | 'Uploading' | 'Ready' | 'Failed';
export type TUploaderState = {
    status: TUploadStatus,
    filePath: string | null,
    uploadKey: string | null,
    file: TFileInfo | null | undefined,
    progress: number;
}

type TDraft = Draft<TUploaderState>;

export class FileUploader {
    constructor(
        private readonly uploadApi: IFileUploadApi,
        private readonly tempFileApi:ITempFileApi,
        private readonly options: TUploaderOptions) {
        if (String.isEmpty(options.id)) options.id = Guid.newId();
        const sliceName = `file-uploader-${options.id}`;
        delete fileCollections[sliceName];

        const [slice, getState] = StoreManager.createSlice({
            name: sliceName,
            initialState: <TUploaderState>{
                status: 'None',
                filePath: '',
                uploadKey: '',
                file: undefined,
                progress: 0
            },
            reducers: {
                setFile: (state, action: PayloadAction<TFileInfo>) => {
                    state.file = action.payload;
                },
                setAsUploading: (state, action: PayloadAction<string>) => {
                    state.status = 'Uploading';
                    state.filePath = '';
                    state.uploadKey = action.payload;
                    if (state.file) state.file.filePath = '';
                },
                setAsReady: (state, action: PayloadAction<string>) => {
                    state.status = 'Ready';
                    state.file!.filePath = state.filePath = action.payload;
                    state.uploadKey = '';
                    delete fileCollections[this.slice.name];
                },
                setAsFailed: (state) => {
                    state.status = 'Failed';
                    state.filePath = '';
                    if (state.file) state.file.filePath = '';
                },
                setAsPending: (state) => {
                    state.status = 'Pending';
                    state.filePath = '';
                    state.uploadKey = '';
                    state.progress = 0;
                    if (state.file) state.file.filePath = '';
                },
                cancel: (state) => {
                    state.status = 'None';
                    state.file = null;
                    state.filePath = '';
                    state.uploadKey = '';
                    state.progress = 0;
                    delete fileCollections[this.slice.name];
                },
                advance: (state: TDraft, action: PayloadAction<number>) => {
                    state.progress += action.payload;
                }
            }
        });

        this.slice = slice;
        this.getState = getState;
    }

    //---- Publics ↓ ------------------------
    public getProgress = () => {
        let st = this.getState();
        return st.status == 'Uploading' ? st.progress : 0;
    }
    public getStatus = (): TUploadStatus => this.getState().status
    public browse = () => {
        let st = this.getState();
        if (st.status != 'None') return;

        opneFileBrowseDialog(this.options).then(((res: IResult<File>) => {
            if (res.isSuccessful && res.data) {
                fileCollections[this.slice.name] = res.data;
                StoreManager.dispatch(this.slice.actions.setFile({
                    name: res.data.name,
                    size: res.data.size,
                    contentType: res.data.type,
                    filePath: null
                }));
                setTimeout(this.startUpload.bind(this, res.data!), 20);
            }
            else
                MessageBox.toast.show(res);
        }).bind(this));
    }

    public getFileInfo = (): TFileInfo | null | undefined => this.getState().file


    cancel = async () => {
        let st = this.getState();
        StoreManager.dispatch(this.slice.actions.cancel());

        if (!String.isEmpty(st.filePath))
            await this.tempFileApi.remove(st.filePath ?? '');

        if (!String.isEmpty(st.uploadKey))
            await this.uploadApi.cancel({ uploadKey: st.uploadKey ?? '' })
    }
    retry = () => {
        let file = fileCollections[this.slice.name];
        if (file instanceof File) this.startUpload(file);
    }
    //---- Privates ↓ -----------------------
    private readonly getState!: () => TUploaderState;
    private readonly slice!: Slice<TUploaderState, {
        setFile: (state: TDraft, action: PayloadAction<TFileInfo>) => void,
        // setStatus: (state: TDraft, action: PayloadAction<TUploadStatus>) => void,
        setAsPending: (state: TDraft) => void,
        setAsUploading: (state: TDraft, action: PayloadAction<string>) => void,
        setAsReady: (state: TDraft, action: PayloadAction<string>) => void,
        setAsFailed: (state: TDraft) => void,
        advance: (state: TDraft, action: PayloadAction<number>) => void,
        cancel: (state: TDraft) => void
    }>

    private setStatus = (status: TUploadStatus, payload?: string) => new Promise<void>(resolve => {
        if (this.getStatus() == status) return resolve();

        switch (status) {
            case 'Pending': setTimeout((() => { StoreManager.dispatch(this.slice.actions.setAsPending()); resolve() }).bind(this), 5); break;
            case 'Uploading': setTimeout((() => { StoreManager.dispatch(this.slice.actions.setAsUploading(payload ?? '')); resolve(); }).bind(this), 5); break;
            case 'Failed': setTimeout((() => { StoreManager.dispatch(this.slice.actions.setAsFailed()); resolve(); }).bind(this), 5); break;
            case 'Ready': setTimeout((() => { StoreManager.dispatch(this.slice.actions.setAsReady(payload ?? '')); resolve(); }).bind(this), 5); break;
        }
    });
    private async startUpload(file: File) {
        const self = this;
        await this.setStatus('Pending');
        let startResp = await this.uploadApi.start({ contentType: file.type, fileName: file.name, fileSize: file.size });
        if (!startResp.isSuccessful || !startResp.data) {
            await this.setStatus('Failed');
            return;
        }
        await this.setStatus('Uploading', startResp.data.uploadKey);

        const threadCount = startResp.data.totalChunks < 5 ? startResp.data.totalChunks : 5
            , pages = Math.ceil(startResp.data.totalChunks / threadCount)
            , percent = 100 / startResp.data.totalChunks;

        let offset = 0, failed = false, uploadedChunks = 0;

        for (let p = 0; p < pages && !failed && this.getStatus() == 'Uploading'; p++) {
            let remainChunks = startResp.data.totalChunks - uploadedChunks;
            let promises = new Array(remainChunks > threadCount ? threadCount : remainChunks);
            uploadedChunks += promises.length;

            for (let i = 0; i < promises.length && !failed && this.getStatus() == 'Uploading'; i++) {
                let fileSlice = file.slice(offset, offset + startResp.data.chunkSize, file.type);
                offset += fileSlice.size;

                promises[i] = this.uploadApi.upload({
                    uploadKey: startResp.data.uploadKey,
                    chunkIndex: i + (p * threadCount),
                    chunk: fileSlice
                }).then(res => {
                    if (!res.isSuccessful)
                        failed = true;
                    else {
                        setTimeout(() => StoreManager.dispatch(self.slice.actions.advance(percent)), 10);
                    }
                })
                    .catch(err => failed = true)
            }

            await Promise.all(promises);

        }

        if (this.getStatus() == 'Uploading') {
            if (failed)
                await this.setStatus('Failed');
            else if (offset > 0) {
                let resp = await this.uploadApi.commit({ uploadKey: startResp.data.uploadKey });
                if (resp.isSuccessful) {
                    await this.setStatus('Ready', resp.data!.filePath);
                    MessageBox.toast.fileUploadSuccessfull();
                }
                else
                    await this.setStatus('Failed');
            }
            else
                this.cancel();
        }


    }
}