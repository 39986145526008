import { Outlet } from "react-router-dom";
import { MainMenu } from "./components/main-menu";
import { Navbar } from "./components/navbar";
import { MainLayoutService } from "@app/services";
import { useSelector } from "react-redux";

export function MainLayout() {
    const layoutService = MainLayoutService.instance,
        containerWidth = useSelector(layoutService.getContentWidth),
        sidebarId = 'main-menu';

    return <div className="layout layout-main wrapper d-flex align-items-stretch">
        <MainMenu id={sidebarId} />
        <div className="container-fluid" id="rootlayout" style={{ width: containerWidth }}>
            <Navbar sidebarId={sidebarId} />
            <Outlet />
        </div>
    </div>
}