import './style.scss'
import React, { useEffect, useMemo, useState } from "react";

export type TDateInputProps = {
    id: string,
    name?: string,
    label?: string,
    description?: string,
    optional?: boolean,
    tabIndex?: number,
    value?: string,
    fillOutRequiredFieldMessage?: string,
    invalidValueMessage?: string,
    onChanged?: (value: string, e?: React.ChangeEvent<HTMLInputElement>) => void
}
export const DateInput = function (props: TDateInputProps) {
    const componentId = useMemo(() => `date-input-${Guid.newId()}`, [])
        , name = useMemo(() => String.isEmpty(props.name) ? props.id : props.name, [props.id, props.name])
        , value = useMemo(() => (props.value ?? '').split('/').map(x => x.trim()).join(' '), [props.value])
        , fillOutRequiredFieldMessage = useMemo(() => String.isEmpty(props.fillOutRequiredFieldMessage) ? I18n.translates.MSG_Warn_FillOutField : props.fillOutRequiredFieldMessage!, [props.fillOutRequiredFieldMessage])
        , invalidValueMessage = useMemo(() => String.isEmpty(props.fillOutRequiredFieldMessage) ? I18n.translates.MSG_Warn_InvalidValue : props.fillOutRequiredFieldMessage!, [props.invalidValueMessage])
        , [keyInfo, setKeyInfo] = useState({ backspace: false, delete: false, digit: false })
        , onInput = (e: React.FormEvent<HTMLInputElement>) => { (e.target as HTMLInputElement).setCustomValidity(''); }
        , onInvalid = (e: React.FormEvent<HTMLInputElement>) => {
            let input = (e.target as HTMLInputElement);
            input.setCustomValidity(
                String.isEmpty(input.value)
                    ? fillOutRequiredFieldMessage
                    : (String.isEmpty(input.validationMessage) ? invalidValueMessage : input.validationMessage)
            );
        }
        , onKeyDown = (e: React.KeyboardEvent<HTMLInputElement>) => {
            if (!keyIsValid(e.key, e.ctrlKey)) {
                e.stopPropagation();
                e.preventDefault();
                return;
            }
            setKeyInfo({
                backspace: e.key == 'Backspace',
                delete: e.key == 'Delete',
                digit: /\d/.test(e.key)
            });
        }
        , onChanged = (e: React.ChangeEvent<HTMLInputElement>) => {
            try {
                let input = (e.target as HTMLInputElement), val = input.value, ss = input.selectionStart;

                if (keyInfo.digit && (val.length == 2 || val.length == 5))
                    val += ' ';
                else if (keyInfo.backspace && (input.selectionStart == 2 || input.selectionStart == 5)) {
                    val = val.removeAt(input.selectionStart - 1);
                }
                else if (keyInfo.delete && val.length > 0) {
                    if (ss && (ss == 2 || ss == 5))
                        val = val.removeAt(ss);
                    else if (ss && ss > 0 && val[ss - 1] == ' ')
                        val = val.removeAt(ss - 1);

                    val = val.replaceAll(' ', '').multiInsert({ 2: ' ', 4: ' ' }).replaceAll('  ', ' ');
                }
                props.onChanged instanceof Function && props.onChanged(val.replaceAll(' ', '/'), e);
                keyInfo.delete && setTimeout(((x: number | null) => { input.selectionStart = input.selectionEnd = x; }).bind(input, ss), 2);
            }
            finally {
                setKeyInfo({
                    backspace: false,
                    delete: false,
                    digit: false
                });
            }
        }

    useEffect(() => { setTimeout(console.clear, 1) }, []);

    return <div className="mb-3 form-input-element date-input" id={componentId}>
        {!String.isEmpty(props.label) && <label htmlFor={props.id} className="form-label" aria-required={!props.optional}>{props.label}</label>}
        {!String.isEmpty(props.description) && <p>{props.description}</p>}
        <div><input type="text"
            id={props.id}
            name={name}
            maxLength={10}
            value={value}
            aria-aria-valuenow={props.value}
            onKeyDown={onKeyDown}
            onChange={onChanged}
            onInput={onInput}
            onInvalid={onInvalid}
            placeholder='dd mm yyyy'
            className="form-control" /></div>

    </div>
}

const keyIsValid = (key: string, control: boolean) => {
    return /\d|ArrowLeft|ArrowRight|Home|End|Tab|Backspace|Delete/i.test(key) || (control && /z|y|c|v/i.test(key));
};