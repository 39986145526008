export type TLabeledMemoProps = {
    id: string,
    label?: string,
    description?: string,
    css?: string,
    maxLength?: number,
    tabIndex?: number,
    rows?: number,
    autoFocus?: boolean,
    disabled?: boolean,
    readonly?: boolean,
    optional?:boolean,
    value?: string | undefined | null,
    onChanged?: (value: string, e?: React.ChangeEvent) => void,
};

export function TextArea(props: TLabeledMemoProps) {
    return <div className={`mb-3 form-input-element multi-line-text-input ${props.css ?? ''}`.trim()}>
        {!String.isEmpty(props.label) && <label htmlFor={props.id} className="form-label" aria-required={!props.optional}>{props.label}</label>}
        {!String.isEmpty(props.description) && <p>{props.description}</p>}
        <textarea value={props.value ?? ''} onChange={e => props.onChanged && props.onChanged(e.target.value, e)} readOnly={props.readonly} disabled={props.disabled} autoFocus={props.autoFocus} className="form-control form-control-sm" id={props.id} rows={props.rows ? props.rows : 3} tabIndex={props.tabIndex} maxLength={props.maxLength}></textarea>
    </div>
}