import { LangSelect } from "../lang-selector"

export const AuthNavBar = function () {
    return <nav className="navbar navbar-expand-sm bg-body-tertiary" data-bs-theme="dark">
        <div className="container-fluid">
            <div className="collapse navbar-collapse" id="navbarNavDropdown">
                <ul className="navbar-nav">
                    <LangSelect />
                </ul>
            </div>
        </div>
    </nav>
}

