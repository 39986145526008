import { routeTypes } from "dariosoft/framework/"
import { Navigate, RouteObject, } from 'react-router-dom'
import * as shared from '@app/areas/shared'
import * as auth from '@app/areas/auth'
import * as main from '@app/areas/main'
import { Icons } from "./icons"

const buildRoute = (nodes: routeTypes.TRouteNode[], node: routeTypes.TRouteNode): RouteObject => {
    let item: any;

    let route: RouteObject = item = {
        path: node.isIndex ? undefined : node.path,
        element: node.element,
        handle: node.handle,
        errorElement: <shared.ErrorPage />,
        children: node.isIndex ? undefined : nodes.filter(n => !n.area && n.parentNode === node.node).map(n => buildRoute(nodes, n)),
    };

    item.index = Boolean(node.isIndex);
    item.componentName = node.componentName;

    if (node.isIndex) {
        delete route.path;
        delete route.children;
    }

    return route;
},
    getNodes = (): routeTypes.TRouteNode[] => {
        I18n.translates = I18n.translates ?? {}
        let map: { [key: string]: routeTypes.TRouteNodeBase } = {
            /* --- Auth ↓ --- */
            '1': { area: true, path: '/auth/', element: <auth.AuthLayout /> },
            '1.2': { path: '/auth/login', isIndex: true, element: <auth.Login />, title: I18n.translates.Login },
            '1.3': { path: '/auth/forgot-password', element: <auth.ForgotPassword />, title: I18n.translates.Forgot_Password },

            /* --- App ↓ --- */
            '2': { area: true, path: '/', element: <main.MainLayout />, title: I18n.translates.HomePage, icon: Icons.home },
            '2.1': { path: '/forbidden', element: <shared.Forbidden />, title: I18n.translates.Forbidden },
            '2.2': { path: '/not-found', element: <shared.NotFound />, title: I18n.translates.NotFound },
            '2.3': { path: '/sys-error', element: <shared.ErrorPage />, title: I18n.translates.Error },
            '2.9': { path: '/dashboard', isIndex: true, element: <main.Home />, title: I18n.translates.HomePage, icon: Icons.home },

            /* --- Client ↓ --- */
            '2.20': { path: '/clients', element: <main.Clients />, title: I18n.translates.Clients, icon: Icons.clients },
            '2.20.1': { path: '/clients/:clientId/templates', element: <main.ClientTemplates />, title: I18n.translates.ClientTemplates, icon: Icons.template },
            '2.20.1.1': { path: '/clients/:clientId/templates/:templateId/context', element: <main.TemplateContext />, title: I18n.translates.Context, icon: Icons.templateContext },
            
            /* --- Settings ↓ --- */
            '2.30': { path: '/user-profile', element: <main.UserProfile />, title: I18n.translates.UserProfile, icon: Icons.userSettings },
            '2.31': { path: '/smtp-settings', element: <main.SmtpSettings />, title: I18n.translates.SmtpSettings, icon: Icons.emailSettings },
            '2.32': { path: '/forgot-password-settings', element: <main.ForgotPasswordSettings />, title: I18n.translates.ForgotPasswordSettings, icon: Icons.forgotPasswordSettings },

        }

        let items = Object.keys(map)
            .map((key) => {
                let item: routeTypes.TRouteNode = map[key] as routeTypes.TRouteNode;

                let i = key.lastIndexOf('.');
                item.node = key;
                item.parentNode = i > 0 ? key.substring(0, i) : undefined;
                item.handle = {
                    title: item.title,
                    icon: item.icon,
                    isAutu: item.path.startsWith('/auth/') || item.path == '/auth',
                    parentPath: map[item.parentNode ?? '']?.path ?? '/'
                };

                return item;
            })

        return items;
    }


export const RouteManager = (function () {
    const nodes = getNodes();

    return Object.freeze({
        getPathInfo: (location: Location): routeTypes.TRouteNode | undefined => {
            let path = (location.hash.startsWith('#') ? location.hash.substring(1) : location.hash).trim();
            if (String.isEmpty(path)) path = '/';
            return nodes.find(n => n.path === path);
        },
        getRoutes: (): RouteObject[] => {
            let items: any[] = nodes
                .filter(node => node.area)
                .ascSort(node => node.node)
                .map(node => buildRoute(nodes, node));

            let sharedItems = [
                { path: '/*', element: <Navigate to='/not-found' /> }
            ];

            return [
                {
                    path: "",
                    element: <shared.AppRoot />,
                    children: items.concat(sharedItems),
                    handle: {
                        title: I18n.translates.HomePage
                    }
                }
            ];
        },
        navigateTo: {
            notFound: (): void => { window.location.href = '#/not-found' },
            accessForbidden: (): void => { window.location.href = '#/forbidden' }
        }
    });
})();