import { Draft, PayloadAction, Slice } from "@reduxjs/toolkit";
import { StoreManager } from "../store-manager";
import { TLoginResult, TUpdateEmailModel } from "../types/auth-types";
import { SessionManager } from "../session-manager";
import { UserApi } from "../api/user-api";
import { MessageBox } from "@dariosoft/components";

type TUpdateEmailModelKey = keyof TUpdateEmailModel;
type TSetUpdateEmailModelPayload = { key: TUpdateEmailModelKey, value: TUpdateEmailModel[TUpdateEmailModelKey] }

type TState = {
    loading: boolean,
    updateEmailModel: TUpdateEmailModel,
}

type TDraft = Draft<TState>;
type TReducers = {
    setUpdateEmailModel: (state: TState, action: PayloadAction<TSetUpdateEmailModelPayload>) => void
};

export class UserProfileService {
    private constructor() {
        let store = StoreManager.createSlice<TState, TReducers>({
            name: 'services/user-profile-service',
            initialState: {
                loading: false,
                updateEmailModel: {
                    email: SessionManager.instance.getInfo().email.trim().toLowerCase()
                }
            },
            reducers: {
                setUpdateEmailModel: (state: TState, action: PayloadAction<TSetUpdateEmailModelPayload>) => {
                    let val = action.payload.value.trim().toLowerCase();
                    state.updateEmailModel[action.payload.key] = val;
                }
            },
            extraReducers: builder => {
                builder.addCase(UserApi.instance.updateEmail.thunk.pending, state => { state.loading = true })
                    .addCase(UserApi.instance.updateEmail.thunk.rejected, state => { state.loading = false })
                    .addCase(UserApi.instance.updateEmail.thunk.fulfilled, (state, action) => {
                        state.loading = false;
                        if (action.payload.isSuccessful) {
                            let info: TLoginResult = { ...SessionManager.instance.getInfo(), email: action.meta.arg.email };
                            SessionManager.instance.persits(info);
                            MessageBox.toast.submitSuccessfull();
                        }
                    })
            }
        });

        this.slice = store[0];
        this.getState = store[1];
    }

    public readonly updateEmail = {
        getModel: () => this.getState().updateEmailModel,
        setModel: (key: TUpdateEmailModelKey, value: TUpdateEmailModel[TUpdateEmailModelKey]) => {
            if (this.getState().updateEmailModel[key] == value) return;
            StoreManager.dispatch(this.slice.actions.setUpdateEmailModel({ key: key, value: value }));
        },
        reset: () => {
            let email = SessionManager.instance.getInfo().email.trim().toLowerCase();
            if (email == this.getState().updateEmailModel.email) return;
            StoreManager.dispatch(this.slice.actions.setUpdateEmailModel({ key: 'email', value: email }));
        },
        submit: () => {
            let model = { ...this.getState().updateEmailModel };

            if (model.email == SessionManager.instance.getInfo().email.trim().toLowerCase()) return;
            UserApi.instance.updateEmail.submit(model);
        }
    }

    public getLoading = (): boolean => this.getState().loading;

    public static get instance(): UserProfileService {
        if (UserProfileService._instance == null)
            UserProfileService._instance = new UserProfileService();

        return UserProfileService._instance!;
    }

    public reset = (): void => {
        this.updateEmail.reset();
    }

    //#region privates
    private static _instance: UserProfileService | null = null;
    private readonly getState!: () => TState;
    private readonly slice!: Slice<TDraft, TReducers>;
    //#endregion
}