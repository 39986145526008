import { Draft, PayloadAction, Slice } from "@reduxjs/toolkit";
import { TForgotPasswordSettings, TSmtpSettings } from "../types/settings-types";
import { StoreManager } from "../store-manager";
import { MessageBox } from "@dariosoft/components";
import { SettingsApi } from "../api/settings-api";

type TSmtpSettingsKeys = keyof TSmtpSettings;
type TForgotPasswordSettingsKeys = keyof TForgotPasswordSettings;

type TState = {
    loading: boolean,
    smtp: TSmtpSettings,
    forgotPassword: TForgotPasswordSettings
}

type TDraft = Draft<TState>;
type TReducers = {
    setSmtpSetting: <T extends TSmtpSettingsKeys>(state: TDraft, action: PayloadAction<{ key: T, value: TSmtpSettings[T] }>) => void
    setForgotPasswordSetting: <T extends TForgotPasswordSettingsKeys>(state: TDraft, action: PayloadAction<{ key: T, value: TForgotPasswordSettings[T] }>) => void
}

export class SettingsService {
    private constructor() {
        let [slice, getState] = StoreManager.createSlice<TState, TReducers>({
            name: 'services/smtp-service',
            initialState: {
                loading: false,
                smtp: {
                    hostAddress: '',
                    portNumber: 587,
                    sslEnabled: true,
                    accountUserName: '',
                    accountDisplayName: undefined,
                    accountPassword: ''
                },
                forgotPassword: {
                    emailSubject: '',
                    template: '',
                }
            },
            reducers: {
                setSmtpSetting: <T extends TSmtpSettingsKeys>(state: TDraft, action: PayloadAction<{ key: T, value: TSmtpSettings[T] }>) => {
                    state.smtp[action.payload.key] = action.payload.value;
                },
                setForgotPasswordSetting: <T extends TForgotPasswordSettingsKeys>(state: TDraft, action: PayloadAction<{ key: T, value: TForgotPasswordSettings[T] }>) => {
                    state.forgotPassword[action.payload.key] = action.payload.value;
                }
            },
            extraReducers: builder => {
                builder.addCase(SettingsApi.instance.smtp.get.thunk.pending, state => { state.loading = true })
                    .addCase(SettingsApi.instance.smtp.get.thunk.rejected, state => { state.loading = false })
                    .addCase(SettingsApi.instance.smtp.get.thunk.fulfilled, (state, action) => {
                        state.loading = false;
                        if (action.payload.isSuccessful && isPlainObject(action.payload.data)) {
                            state.smtp.hostAddress = action.payload.data!.hostAddress;
                            state.smtp.portNumber = action.payload.data!.portNumber;
                            state.smtp.sslEnabled = action.payload.data!.sslEnabled;
                            state.smtp.accountUserName = action.payload.data!.accountUserName;
                            state.smtp.accountPassword = action.payload.data!.accountPassword;
                            state.smtp.accountDisplayName = action.payload.data!.accountDisplayName;
                            setTimeout(this.onSmtpSettingLoaded.bind(this), 2);
                        }
                    })
                    .addCase(SettingsApi.instance.smtp.update.thunk.pending, state => { state.loading = true })
                    .addCase(SettingsApi.instance.smtp.update.thunk.rejected, state => { state.loading = false })
                    .addCase(SettingsApi.instance.smtp.update.thunk.fulfilled, (state, action) => {
                        state.loading = false;
                        if (action.payload.isSuccessful) {
                            MessageBox.toast.submitSuccessfull();
                        }
                    })

                    //===========================
                    .addCase(SettingsApi.instance.forgotPassword.get.thunk.pending, state => { state.loading = true })
                    .addCase(SettingsApi.instance.forgotPassword.get.thunk.rejected, state => { state.loading = false })
                    .addCase(SettingsApi.instance.forgotPassword.get.thunk.fulfilled, (state, action) => {
                        state.loading = false;
                        if (action.payload.isSuccessful && isPlainObject(action.payload.data)) {
                            state.forgotPassword.emailSubject = action.payload.data!.emailSubject;
                            state.forgotPassword.template = action.payload.data!.template;
                        }
                    })
                    .addCase(SettingsApi.instance.forgotPassword.update.thunk.pending, state => { state.loading = true })
                    .addCase(SettingsApi.instance.forgotPassword.update.thunk.rejected, state => { state.loading = false })
                    .addCase(SettingsApi.instance.forgotPassword.update.thunk.fulfilled, (state, action) => {
                        state.loading = false;
                        if (action.payload.isSuccessful) {
                            MessageBox.toast.submitSuccessfull();
                        }
                    })
            }
        });

        this.slice = slice;
        this.getState = getState;
    }

    public getLoading = (): boolean => this.getState().loading;

    public readonly smtp = Object.freeze({
        getModel: (): TSmtpSettings => this.getState().smtp,
        setModel: <T extends TSmtpSettingsKeys>(key: T, value: TSmtpSettings[T]): void => {
            if (this.smtp.getModel()[key] == value) return;
            StoreManager.dispatch(this.slice.actions.setSmtpSetting({ key: key, value: value }));
        },
        update: async (): Promise<void> => {
            let confirm = await MessageBox.modal.confirmSave();
            if (!confirm) return;
            let model = { ...this.smtp.getModel() };
            model.accountPassword = await darioCrypto.lock(model.accountPassword);
            SettingsApi.instance.smtp.update.submit(model);
        },
        load: (): void => SettingsApi.instance.smtp.get.submit()
    });

    public readonly forgotPassword = Object.freeze({
        getModel: (): TForgotPasswordSettings => this.getState().forgotPassword,
        setModel: <T extends TForgotPasswordSettingsKeys>(key: T, value: TForgotPasswordSettings[T]): void => {
            if (this.forgotPassword.getModel()[key] == value) return;
            StoreManager.dispatch(this.slice.actions.setForgotPasswordSetting({ key: key, value: value }));
        },
        update: async (): Promise<void> => {
            let confirm = await MessageBox.modal.confirmSave();
            if (!confirm) return;
            let model = { ...this.forgotPassword.getModel() };
            SettingsApi.instance.forgotPassword.update.submit(model);
        },
        load: (): void => SettingsApi.instance.forgotPassword.get.submit()
    });

    public static get instance(): SettingsService {
        if (SettingsService._instance == null)
            SettingsService._instance = new SettingsService();

        return SettingsService._instance!;
    }

    //#region privates
    private static _instance: SettingsService | null = null;
    private readonly getState!: () => TState;
    private readonly slice!: Slice<TDraft, TReducers>;
    private onSmtpSettingLoaded() {
        let pass = this.smtp.getModel().accountPassword;
        darioCrypto.unlock(pass).then(unlockedVal => {
            this.smtp.setModel('accountPassword', unlockedVal);
        });
    }
    //#endregion
}