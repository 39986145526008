import { Draft, PayloadAction, Slice } from "@reduxjs/toolkit";
import { StoreManager } from "../store-manager";
import { templateTypes } from '@app/types'
import * as api from '@app/api';
import { MessageBox } from "@dariosoft/components";

type TModelKey = keyof templateTypes.TTemplateContext;

type TState = {
    loading: boolean,
    mailAddress: string,
    model: templateTypes.TTemplateContext
}

type TReducers = {
    setMailAddress: (state: Draft<TState>, action: PayloadAction<string>) => void,
    setModel: (state: Draft<TState>, action: PayloadAction<{ key: TModelKey, value: any }>) => void
}

export class TemplateContextService {
    constructor(private readonly templateId: string) {
        let store = StoreManager.createSlice<TState, TReducers>({
            name: 'TemplateContextService',
            initialState: {
                loading: false,
                mailAddress: '',
                model: {
                    templateId: '',
                    dataModel: '{}',
                    content: ''
                }
            },
            reducers: {
                setMailAddress: (state: Draft<TState>, action: PayloadAction<string>) => {
                    state.mailAddress = action.payload;
                },
                setModel: (state: Draft<TState>, action: PayloadAction<{ key: TModelKey, value: any }>) => {
                    if (action.payload.key == 'templateId') return;
                    state.model[action.payload.key] = action.payload.value;
                }
            },
            extraReducers: builder => {
                builder
                    .addCase(api.TemplateApi.instance.getContext.thunk.pending, state => { state.loading = true })
                    .addCase(api.TemplateApi.instance.getContext.thunk.rejected, state => { state.loading = false })
                    .addCase(api.TemplateApi.instance.getContext.thunk.fulfilled, (state, action) => {
                        state.loading = false;
                        if (action.payload.isSuccessful && isPlainObject(action.payload.data)) {
                            state.model.templateId = action.meta.arg;
                            state.model.dataModel = action.payload.data!.dataModel;
                            state.model.content = action.payload.data!.content;
                        }
                    })


                    .addCase(api.TemplateApi.instance.setContext.thunk.pending, state => { state.loading = true })
                    .addCase(api.TemplateApi.instance.setContext.thunk.rejected, state => { state.loading = false })
                    .addCase(api.TemplateApi.instance.setContext.thunk.fulfilled, (state, action) => {
                        state.loading = false;
                        if (action.payload.isSuccessful) {
                            MessageBox.toast.submitSuccessfull();
                        }
                    })

                    .addCase(api.TemplateApi.instance.mailTo.thunk.pending, state => { state.loading = true })
                    .addCase(api.TemplateApi.instance.mailTo.thunk.rejected, state => { state.loading = false })
                    .addCase(api.TemplateApi.instance.mailTo.thunk.fulfilled, (state, action) => {
                        state.loading = false;
                        if (action.payload.isSuccessful) {
                            MessageBox.toast.success(`A mail message has been sent to "${action.meta.arg.mailAddress}".`);
                        }
                    })
                    ;
            }
        }, true);

        this.slice = store[0];
        this.getState = store[1];
    }

    public getLoading = (): boolean => this.getState().loading;
    public getModel = (): templateTypes.TTemplateContext => this.getState().model;
    public setModel = <TKey extends TModelKey>(key: TKey, value: templateTypes.TTemplateContext[TKey]) => {
        if (key == 'templateId') return;
        StoreManager.dispatch(this.slice.actions.setModel({ key: key, value: value }));
    }
    public load = (): void => api.TemplateApi.instance.getContext.submit(this.templateId);
    public save = (): void => {
        MessageBox.modal.confirmSave().then(confirm => {
            if (!confirm) return;
            let model = { ...this.getState().model };
            api.TemplateApi.instance.setContext.submit(model);
        });
    }

    public readonly mailTo = Object.freeze({
        getMail: () => this.getState().mailAddress,
        setMail: (val: string) => {
            val = (val ?? '').trim().toLowerCase();
            if (this.mailTo.getMail() == val) return;
            StoreManager.dispatch(this.slice.actions.setMailAddress(val));
        },
        submit: () => {
            let state = this.getState();
            api.TemplateApi.instance.mailTo.submit({ templateId: state.model.templateId, mailAddress: state.mailAddress });
        }
    });

    //#region PRIVATES
    private readonly getState!: () => TState;
    private readonly slice!: Slice<TState, TReducers>;
    //#endregion
}