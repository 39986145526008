import { SubLayout } from "@dariosoft/components";
import { ClientList } from "./list";

export * from './list'
export * from './templates'
export * from './template-editor'

export function Clients() {
    return <SubLayout layoutRouteMatch="/clients">
        <ClientList />
    </SubLayout>
}