export type TEventListener = (e: Event) => void;
export type TEventListenerT<T> = (e: CustomEvent<T>) => void;

export class EventCase {
    private readonly handlers: { [key: string]: EventListener; } = {};

    constructor(private readonly target: EventTarget, private readonly name: string) { }

    public dispatch = (): void => {
        setTimeout(() => this.target.dispatchEvent(new Event(this.name, {})), 20);
    }

    public addListener(handler: EventListener): string {
        if (!(handler instanceof Function))
            return '';

        let key = this.findByHandler(handler)[0];

        if (!String.isEmpty(key)) return key;

        key = `${this.name}-${Guid.newId()}`;

        this.handlers[key] = handler;

        this.target.addEventListener(this.name, this.handlers[key]);

        return key;
    }

    public removeListener = (handler: EventListener): number => this.removeListeners(this.findByHandler(handler));

    public removeAllListeners = (): number => this.removeListeners(Object.keys(this.handlers));

    private removeListeners(keys: string[]): number {
        for (var i = 0; i < keys.length; i++) {
            this.target.removeEventListener(this.name, this.handlers[keys[i]]);
            delete this.handlers[keys[i]];
        }
        return keys.length;
    }

    private findByHandler(handler: EventListener): string[] {
        return Object.keys(this.handlers)
            .filter(key => this.handlers[key] === handler);
    }
}

export class EventCaseT<T> {
    private readonly handlers: { [key: string]: EventListenerObject; } = {};

    constructor(private readonly target: EventTarget, private readonly name: string) { }

    public dispatch = (detail: T): void => {
        setTimeout(() => this.target.dispatchEvent(new CustomEvent(this.name, { detail: detail })), 20);
    }

    public addListener(handler: TEventListenerT<T>): string {
        if (!(handler instanceof Function))
            return '';

        let key = this.findByHandler(handler)[0];

        if (!String.isEmpty(key)) return key;

        key = `${this.name}-${Guid.newId()}`;

        this.handlers[key] = { handleEvent: handler };

        this.target.addEventListener(this.name, this.handlers[key]);

        return key;
    }

    public removeListener = (handler: TEventListenerT<T>): number => this.removeListeners(this.findByHandler(handler));

    public removeAllListeners = (): number => this.removeListeners(Object.keys(this.handlers));

    private removeListeners(keys: string[]): number {
        for (var i = 0; i < keys.length; i++) {
            this.target.removeEventListener(this.name, this.handlers[keys[i]]);
            delete this.handlers[keys[i]];
        }
        return keys.length;
    }

    private findByHandler(handler: TEventListenerT<T>): string[] {
        return Object.keys(this.handlers)
            .filter(key => this.handlers[key].handleEvent === handler);
    }
}
