export type TCheckboxProps = {
    id: string,
    label: string,
    maxLength?: number,
    tabIndex?: number,
    autoFocus?: boolean,
    value?: boolean,
    onChanged?: (value: boolean, e?: React.ChangeEvent) => void
};

export function Checkbox(props: TCheckboxProps) {
    return <div className="form-check mb-3">
        <input className="form-check-input" type="checkbox" checked={props.value} onChange={e => props.onChanged && props.onChanged(e.target.checked, e)} id={props.id} autoFocus={props.autoFocus} tabIndex={props.tabIndex} />
        <label className="form-check-label" htmlFor={props.id}>{props.label}</label>
    </div>
}