import { SettingsService } from '@app/services'
import './style.scss'
import { useSelector } from 'react-redux'
import { Checkbox, TForm, TextInput } from '@dariosoft/components';
import { useEffect, useMemo } from 'react';
export function SmtpSettings() {
    const service = SettingsService.instance.smtp,
    loading = useSelector(SettingsService.instance.getLoading),
        model = useSelector(service.getModel),
        port = useMemo(() => !Boolean(model.portNumber) || model.portNumber == 0 ? '' : model.portNumber.toString(), [model.portNumber]),
        otherButtons = <>
            <button onClick={service.load} className='btn btn-sm btn-primary' type='button'>{I18n.translates.Refresh}</button>
        </>
        ;

        useEffect(() => {
            setTimeout(service.load, 50);
        }, []);

    return <div className="vw vw-smtp-settings">
        <TForm id='formSmtpSettings' onSubmit={service.update} otherButtons={otherButtons} loading={loading} submitText={I18n.translates.Update} lastTabIndex={7}>
            <fieldset className='mb-3'>
                <legend>{I18n.translates.MailServer}</legend>
                <TextInput id='txtHostAddress' label={I18n.translates.HostAddress} value={model.hostAddress} onChanged={val => service.setModel('hostAddress', val ?? '')} tabIndex={1} autoFocus />
                <TextInput id='txtPortNumber' label={I18n.translates.PortNumber} value={port} keyboardInputControlPatterns={[/\d+/]} onChanged={val => service.setModel('portNumber', Number.parse(val))} tabIndex={2} />
                <Checkbox id='chkSslEnabled' label={I18n.translates.SslEnabled} value={model.sslEnabled} onChanged={val => service.setModel('sslEnabled', val)} tabIndex={3} />
            </fieldset>
            <fieldset>
                <legend>{I18n.translates.EmailAccount}</legend>
                <TextInput id='txtEmailAccount' type='email' label={I18n.translates.EmailAccount} value={model.accountUserName} onChanged={val => service.setModel('accountUserName', val ?? '')} tabIndex={4} />
                <TextInput id='txtEmailDisplayName' label={I18n.translates.DisplayName} value={model.accountDisplayName} onChanged={val => service.setModel('accountDisplayName', val)} tabIndex={5} optional />
                <TextInput id='txtEmailAccountPassword' type='password' label={I18n.translates.Password} value={model.accountPassword} onChanged={val => service.setModel('accountPassword', val ?? '')} tabIndex={6} />
            </fieldset>
        </TForm>
    </div>
}