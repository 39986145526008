import { createAsyncThunk } from "@reduxjs/toolkit";
import { clientTypes as types } from "@app/types";
import { ApiClient } from "./___ApiClient";
import { StoreManager } from "@app/core";
import { TApiInput } from '@dariosoft/tools'

export class ClientApi extends ApiClient {
    private constructor() {
        super('admin/api/v1/client')
    }

    //#region PUBLICS
    public static get instance(): ClientApi {
        if (ClientApi._instance == null) ClientApi._instance = new ClientApi();
        return ClientApi._instance!;
    }

    public create = {
        thunk: createAsyncThunk('admin/client/create', (payload: TApiInput<types.TClientInfo, {}>) => this.api.create(payload.data)),
        submit: (payload: TApiInput<types.TClientInfo, {}>) => StoreManager.dispatch(this.create.thunk(payload) as any)
    }
    public update = {
        thunk: createAsyncThunk('admin/client/update', (payload: TApiInput<types.TClientInfo, {}>) => this.api.update(payload.data)),
        submit: (payload: TApiInput<types.TClientInfo, {}>) => StoreManager.dispatch(this.update.thunk(payload) as any)
    }
    public delete = {
        thunk: createAsyncThunk('admin/client/delete', (payload: TApiInput<types.TClientModel, {}>) => this.api.delete(payload.data.id)),
        submit: (payload: TApiInput<types.TClientModel, {}>) => StoreManager.dispatch(this.delete.thunk(payload) as any)
    }
    public get = {
        thunk: createAsyncThunk('admin/client/get', (id: string) => this.api.get(id)),
        submit: (id: string) => StoreManager.dispatch(this.get.thunk(id) as any)
    }
    public generateSecretKey = {
        thunk: createAsyncThunk('admin/client/generateSecretKey', (id: string) => this.api.generateSecretKey(id)),
        submit: (id: string) => StoreManager.dispatch(this.generateSecretKey.thunk(id) as any)
    }
    public getCredentials = {
        thunk: createAsyncThunk('admin/client/get/credentials', (id: string) => this.api.getCredentials(id)),
        submit: (id: string) => StoreManager.dispatch(this.getCredentials.thunk(id) as any)
    }
    public setCredentials = {
        thunk: createAsyncThunk('admin/client/set/credentials', (payload: types.TClientCredentialInfo) => this.api.setCredentials(payload)),
        submit: (payload: types.TClientCredentialInfo) => StoreManager.dispatch(this.setCredentials.thunk(payload) as any)
    }
    public list = {
        thunk: createAsyncThunk('admin/client/list', (payload: TApiInput<TListLoadModel, {}>) => this.api.list(payload.data)),
        submit: (payload: TApiInput<TListLoadModel, {}>) => StoreManager.dispatch(this.list.thunk(payload) as any)
    }
    //#endregion

    //#region PRIVATES
    private static _instance: ClientApi | null = null;
    private readonly api = {
        create: (payload: types.TClientInfo): Promise<IResult<TSubmitResultDto>> => this.http.postJson(`create`, payload),
        update: (payload: types.TClientInfo): Promise<IResult> => this.http.postJson(`update`, payload),
        delete: (id: string): Promise<IResult> => this.http.delete(`delete/${id}`),
        get: (id: string): Promise<IResult<types.TClientModel>> => this.http.get(`get/${id}/by-id`),
        generateSecretKey: (id: string): Promise<IResult<string>> => this.http.get(`secretKey/${id}/generate`),
        getCredentials: (id: string): Promise<IResult<types.TClientCredentialInfo>> => this.http.get(`get/${id}/credentials`),
        setCredentials: (payload: types.TClientCredentialInfo): Promise<IResult> => this.http.postJson(`set/credentials`, payload),
        list: (model: TListLoadModel): Promise<IListResult<types.TClientModel>> => this.http.get<IListResult<types.TClientModel>>(`list?${super.getQueryString(model)}`),
    }
    //#endregion
}