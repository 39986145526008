import { createAsyncThunk } from "@reduxjs/toolkit";
import * as types from "@app/types";
import { ApiClient } from "./___ApiClient";
import { StoreManager } from "@app/core";

export class SettingsApi extends ApiClient {
    private constructor() {
        super('admin/api/v1/settings')
    }

    //#region PUBLICS
    public static get instance(): SettingsApi {
        if (SettingsApi._instance == null) SettingsApi._instance = new SettingsApi();
        return SettingsApi._instance!;
    }

    public readonly smtp = Object.freeze({
        get: {
            thunk: createAsyncThunk('settings/smtp/get', () => this.smtpApi.get()),
            submit: () => StoreManager.dispatch(this.smtp.get.thunk() as any)
        },
        update: {
            thunk: createAsyncThunk('settings/smtp/update', (payload: types.TSmtpSettings) => this.smtpApi.update(payload)),
            submit: (payload: types.TSmtpSettings) => StoreManager.dispatch(this.smtp.update.thunk(payload) as any)
        }
    });

    public readonly forgotPassword = Object.freeze({
        get: {
            thunk: createAsyncThunk('settings/forgot-password/get', () => this.forgotPasswordApi.get()),
            submit: () => StoreManager.dispatch(this.forgotPassword.get.thunk() as any)
        },
        update: {
            thunk: createAsyncThunk('settings/forgot-password/update', (payload: types.TForgotPasswordSettings) => this.forgotPasswordApi.update(payload)),
            submit: (payload: types.TForgotPasswordSettings) => StoreManager.dispatch(this.forgotPassword.update.thunk(payload) as any)
        }
    });
    //#endregion

    //#region PRIVATES
    private static _instance: SettingsApi | null = null;
    private readonly smtpApi = {
        get: (): Promise<IResult<types.TSmtpSettings>> => this.http.get(`smtp/get`),
        update: (payload: types.TSmtpSettings): Promise<IResult> => this.http.postJson(`smtp/update`, payload),
    }

    private readonly forgotPasswordApi = {
        get: (): Promise<IResult<types.TForgotPasswordSettings>> => this.http.get(`forgot-password/get`),
        update: (payload: types.TForgotPasswordSettings): Promise<IResult> => this.http.postJson(`forgot-password/update`, payload),
    }
    //#endregion
}