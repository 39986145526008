import './style.scss'
import { useEffect, useMemo } from 'react'
import { FileUploader } from './file-uploader'
import { useSelector } from 'react-redux'
import { IFileUploadApi, ITempFileApi } from './file-uploader-apis'

export type TFileInput = {
    id: string,
    description?: string,
    maxSize: TFileSize,
    accept?: string | null
    label?: string,
    tabIndex?: number,
    autoFocus?: boolean,
    disabled?: boolean,
    optional?: boolean,
    value?: TFileInfo | null | undefined,
    onChanged?: (fileId?: TFileInfo | null) => void,
    uploadApi: IFileUploadApi,
    tempFileApi: ITempFileApi
}

export const FileInput = function (props: TFileInput) {
    const service = useMemo(() => new FileUploader(props.uploadApi, props.tempFileApi, { id: props.id, sizeLimit: props.maxSize, accept: props.accept }), []),
        file = useSelector(service.getFileInfo),
        status = useSelector(service.getStatus),
        progress = useSelector(service.getProgress),
        filename = useMemo(() => props.value?.name ?? '', [props.value, file, status]),
        dynamicStyles = useMemo(() => ({ background: `linear-gradient(to right, #46cf6f ${progress}%, #a7f1bd ${progress > 0 ? (progress + 8) : 0}%, #fff ${progress > 0 ? 100 : 0}%)` }), [progress]);

    useEffect(() => { props.onChanged instanceof Function && props.onChanged(file) }, [file]);

    return <div className="mb-3 form-input-element file-input">
        {!String.isEmpty(props.label) && <label htmlFor={props.id} className="form-label" aria-required={!props.optional}>{props.label}</label>}
        {!String.isEmpty(props.description) && <p>{props.description}</p>}
        <div className={`${status == 'Uploading' ? 'uploading' : ''}`.trim()}>
            <input type='text' id={`${props.id}-fileid`} value={file?.filePath ?? ''} onChange={e => { }} required={!Boolean(props.optional)} className='file-id' />
            <input type="text" id={props.id} style={dynamicStyles} value={filename} onChange={e => { }} className="form-control" />
            {status == 'None' && <button onClick={() => service.browse()} title={I18n.translates.Browse} className='btn-browse' type='button'><i className="bi bi-folder2-open"></i></button>}
            {status == 'Uploading' && <button onClick={() => service.cancel()} title={I18n.translates.Cancel} className='btn-cancel' type='button'><i className="bi bi-ban"></i></button>}
            {(status == 'Ready' || status == 'Failed') && <button onClick={() => service.cancel()} title={I18n.translates.Remove} className='btn-remove' type='button'><i className="bi bi-trash"></i></button>}
            {status == 'Failed' && <button onClick={() => service.retry()} title={I18n.translates.Retry} className='btn-retry' type='button'><i className="bi bi-arrow-clockwise"></i></button>}
        </div>

    </div>
}