export { };

declare global {
    const randomTextGenerator: {
        generateRandomNumber: (length: number, option?: 'skipZeroAtAll' | 'skipZeroFromStart' | 'skipZeroFromEnd' | 'skipStartAndEndZero') => string,
        generateRandomText: (length: number, options?: { ignoreSymbols: boolean, ignoreDigits: boolean, ignoreCase: boolean }) => string,
        generateRandomPassword: (length: number) => string
    }
}

let win: any = window;

win.randomTextGenerator = (function () {

    const alphabet_lower = "abcdefghijklmnopqrstuvwxyz",
        alphabet_upper = "ABCDEFGHIJKLMNOPQRSTUVWXYZ",
        digits = "0123456789",
        symbols = "!@#$%^&*?+-~[]()_={}",
        randomInt = (min: number, max: number): number => Math.floor(Math.random() * (max - min + 1)) + min,
        distinctRate = (text: string): number => {
            const distinctLength = new Set(text).size;
            const length = text.length;
            return distinctLength / length;
        },
        generate = (length: number, topic: string): string => {
            if (length < 1) return '';

            const chars = new Array(length);
            const chunkSize = Math.floor(topic.length / 3);

            let start = 0,
                end = topic.length - 1,
                a = chunkSize + 1,
                b = 2 * chunkSize + 1,
                x = -1;

            for (let i = 0; i < length; i++) {
                x = randomInt(start, end);
                chars[i] = topic[x];

                if (x > 0 && x < a) {
                    start = b;
                    end = topic.length - 1;
                } else if (x > b) {
                    start = a;
                    end = b - 1;
                } else if (x >= a && x <= b) {
                    start = 0;
                    end = a - 1;
                }
            }

            return chars.join('');
        }

    return Object.freeze({
        generateRandomNumber: (length: number, option?: 'skipZeroAtAll' | 'skipZeroFromStart' | 'skipZeroFromEnd' | 'skipStartAndEndZero'): string => {
            let result = '';
            let len = length;

            if (String.isEmpty(option)) {
                result = generate(length, digits);
            } else if (option == 'skipZeroAtAll') {
                result = generate(length, digits.substring(1));
            } else {
                do {
                    result += generate(len, digits);

                    if (option === 'skipZeroFromStart')
                        result = result.replace(/^0+/, '');
                    else if (option === 'skipZeroFromEnd')
                        result = result.replace(/0+$/, '');
                    else if (option === 'skipStartAndEndZero')
                        result = result.replace(/^0+|0+$/g, '');

                    if (distinctRate(result) < 0.5) result = '';

                    len = length - result.length;

                } while (len > 0);
            }

            return result;
        },
        generateRandomText: (length: number, options?: { ignoreSymbols: boolean, ignoreDigits: boolean, ignoreCase: boolean }): string => {
            let topic = Boolean(options?.ignoreCase) ? alphabet_lower : alphabet_lower + alphabet_upper;

            if (!Boolean(options?.ignoreDigits)) topic += digits;
            if (!Boolean(options?.ignoreSymbols)) topic += symbols;

            let result = generate(length, topic);

            while (distinctRate(result) < 0.5) result = generate(length, topic);

            return result;
        },
        generateRandomPassword: (length: number): string => generate(length, alphabet_lower + digits + alphabet_upper)
    });

})();