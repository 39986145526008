export const LangSelect = function () {
    const lang = I18n.getCurrentCulture().title;


    return <li className="nav-item dropdown">
        <a className="nav-link dropdown-toggle" href="#" role="button" data-bs-toggle="dropdown" aria-expanded="false">
            {lang}
        </a>
        <ul className="dropdown-menu langs">
            {I18n.cultures.map((x, i) => <li key={`lang-item-${x.name}`}><a className="dropdown-item" href={I18n.getCurrentUrl(x)}>{x.title}</a></li>)}
        </ul>
    </li>
}