export { };

declare global {
    interface NumberConstructor {
        asFileSizeLabel(fileSize: number): string;
        parse(x: string | undefined | null): number;
    }

    interface Number {
        controlRange: (min: number, max: number) => number,
        controlMin: (min: number) => number,
        controlMax: (max: number) => number,
        hasFlag: (flag: number) => boolean,
    }
}

//#region NumberConstructor
Number.parse = function (x: string | undefined | null): number {
    if (x === undefined || x === null)
        return 0;

    let result = +x;
    if (isNaN(result))
        result = 0;
    return result;
}

Number.asFileSizeLabel = function (fileSize: number): string {
    if (fileSize < 1024) return (fileSize < 2 ? `${fileSize}Byte` : `${fileSize}Bytes`);

    const units: { [key: string]: number } = {
        KB: 1024,
        MB: 1024 * 1024,
        GB: 1024 * 1024 * 1024,
        TB: 1024 * 1024 * 1024 * 1024,
    };

    let unit = Object.keys(units).map(k => ({ value: units[k], text: k })).descSort(x => x.value).find(x => fileSize >= x.value);
    if (!unit) return fileSize.toString();
    let div = fileSize / unit.value, mode = fileSize % unit.value;
    return mode > 0 ? `${div}.${mode}${unit.text}` : `${div}${unit.text}`;
}
//#endregion

//#region Number - Extensions
Number.prototype.hasFlag = function(flag: number): boolean{
    return (this.valueOf() & flag) != 0;
}

Number.prototype.controlRange = function (min: number, max: number): number {
    let x = this.valueOf();
    if (x < min) x = min;
    if (x > max) x = max;
    return x;
}

Number.prototype.controlMin = function (min: number): number {
    let x = this.valueOf();
    if (x < min) x = min;
    return x;
}

Number.prototype.controlMax = function (max: number): number {
    let x = this.valueOf();
    if (x > max) x = max;
    return x;
}
//#endregion