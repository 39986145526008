export type TAuthBannerProps = {
    logo?: string,
    title: string,
    link?: string,
    linkText?: string
};

export function AuthBanner(props: TAuthBannerProps) {
    return <div className='auth-layout-board col-4'>
        {!String.isEmpty(props.logo) && <img src={props.logo} />}
        <h4>{props.title}</h4>
        {!String.isEmpty(props.link) && <a href={props.link}>{String.coalesce(props.linkText, props.link)}</a>}
    </div>
}