export { };

declare global {
    interface BooleanConstructor {
        parse(value: any): boolean;
    }
}

Boolean.parse = (value: any): boolean => {
    if(value === undefined || value === null || value === false || value === 0) return false;
    let x = (value ?? '').toString().trim().toUpperCase();
    return !(x == '' || x == 'FALSE');
}